@import "react-image-gallery/styles/css/image-gallery.css";

@font-face {
    font-family: 'Lato';
    src: url('../font/Lato-Bold.woff2') format('woff2'),
        url('../font/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('../font/Lato-Hairline.woff2') format('woff2'),
        url('../font/Lato-Hairline.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../font/Lato-Black.woff2') format('woff2'),
        url('../font/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../font/Lato-Light.woff2') format('woff2'),
        url('../font/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../font/Lato-Regular.woff2') format('woff2'),
        url('../font/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Afternoon in Stereo Personal Us';
    src: url('../font/AfternooninStereoPersonalUse-Re.woff2') format('woff2'),
        url('../font/AfternooninStereoPersonalUse-Re.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ardina Script';
    src: url('../font/ArdinaScript.woff2') format('woff2'),
        url('../font/ArdinaScript.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brume';
    src: url('../font/Brume-Regular.woff2') format('woff2'),
        url('../font/Brume-Regular.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/* ------------ togale scroller issues Start --- */

::-webkit-scrollbar {
    width: 12px;
    /* Adjust width as needed */
}

body {
    font-family: 'Lato';
    overflow-y: scroll;
    /* Always show vertical scrollbar */
}

/* ------------ togale scroller issue END --- */


/* body {
    font-family: 'Lato';
} */

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0;
}

.container-cos {
    max-width: 80%;
    margin: 0 auto;
}

.page-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

@media (max-width: 768px) {
    .container-cos {
        max-width: 90%;
    }
}

.loader-main {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000a1;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
}

.cart-top-main {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
}

.btn-scroll-top {
    width: 50px;
    height: 50px;
    background-color: #E02E24 !important;
    color: #fff;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.cart-scroll {
    font-size: 20px;
}

.google-login span {
    display: none;
}

.google-login .nsm7Bb-HzV7m-LgbsSe {
    border: none;
    padding: 0;
}

.google-login .nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
    height: auto;
    margin: 0;
    width: auto;
}
.w-6 {
    width: 1.5rem;
}
.h-6 {
    height: 1.5rem;
}
.google-login svg {
    width: 32px;
}


/* header start */
.header-main {
    background: #FFFFFF;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.header {
    padding: 0px 100px;
    height: 70px;
}

.mar-cos {
    margin-top: 100px;
    min-height: calc(100vh - 460px);
}

.header .menu-box ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    align-items: center;
    column-gap: 30px;
}

.header .menu-box ul li {
    height: 100%;
    display: flex;
    align-items: center;
}

.header .menu-box a,
.header .menu-box button,
.new-in-btn {
    text-decoration: none;
    background-color: transparent !important;
    border: none;
    color: #0f1111!important;
    padding: 0;
    font-weight: 700;
    white-space: nowrap;
    font-size: 15px;
}

.header .menu-box a:hover,
.header .menu-box button:hover {
    color: #E02E24 !important;
}

.new-in-btn {
    margin-top: 10px;
}

.header .menu-box a.active {
    color: #E02E24 !important;
}

.header .menu-box a svg {
    font-size: 20px;
}

.header .pre-label-btn {
    background-color: transparent;
    background-color: transparent !important;
    box-shadow: none;
    padding: 0;
    border: none;
}

.header .pre-label-btn h6 {
    color: #0f1111;
    font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
}

.header .pre-label-btn span {
    color: #B1B1B1;
    font-weight: 500;
    font-size: 12px;
    display: inherit;
    white-space: nowrap;
}

.search-filed {
    border: 1px solid #EBF0FF;
    border-radius: 5px;
    padding: 10px;
    flex: 1;
}

.search-filed input {
    border: none;
    outline: none;
}

.search-filed input::-webkit-input-placeholder {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #9098B1;
}

.header .user-account h6 {
    font-size: 13px;
    font-weight: 800 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.header .user-account span {
    font-weight: 500;
    font-size: 13px;
    color: #0f1111;
}

.header .dropdown-toggle::after {
    display: none;
}

.header .search-icon-btn {
    color: #FFFFFF;
    background-color: #E02E24 !important;
    border: none !important;
    display: flex;
    padding: 8px;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}

@media (max-width: 425px) {
    .header .search-icon-btn {
        padding: 7px;
        font-size: 10px;
    }
}

.header .account button {
    background-color: transparent !important;
    border: none;
}

.header .account .login-btn {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #E02E24 !important;
    border-radius: 5px;
    padding: 5px 20px;
    border: none;
}

.header .account .flag-selector {
    padding: 0;
}

.header .dropdown-menu {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
    border-radius: 11px;
    width: 300px;
    padding: 20px;
}

.header .drop-items h6 {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
}

.header a {
    color: #0f1111;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    column-gap: 12px;
    padding: 10px 0 5px 0;
    text-decoration: none;
}

.header .dropdown-item:focus,
.header .dropdown-item:hover {
    background-color: transparent;
    color: #0f1111;
}

.header .select-country h5 {
    font-weight: 600;
    font-size: 16px;
    color: #BDC2D1;
}

.header .select-country label {
    cursor: pointer;
    width: 100%;
}

.header .select-country input {
    appearance: none;
    position: relative;
}

.header .select-country {
    width: 250px;
}

.header .select-country input::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background-image: url("../img/right-mark.png");
    background-size: 12px;
    background-repeat: no-repeat;
    right: -5px;
    display: none;
}

.header .select-country input:checked:after {
    display: block;
}

.header .select-country a {
    padding-top: 5px;
}

.header .toggle {
    color: #0f1b3f !important;
    font-size: 28px;
    display: none;
}

.header .mega-menu {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    width: 1300px;
    max-width: 100%;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0);
    display: none;
    padding: 20px;
}

.header .menu-box ul li:hover .mega-menu {
    display: block;
}

.header .mega-menu ul {
    display: block;
}

.header .mega-menu ul li:first-child {
    margin-top: 0;
}

.header .mega-menu ul li {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 35px;
    padding: 0 10px;
    position: relative;
}

.header .mega-menu ul li:hover::after {
    width: 3px;
    height: 100%;
    background: #E02E24;
    content: "";
    position: absolute;
    left: 0;
}

.header .mega-menu ul li:hover {
    background-color: #fdefee;
}

.header .mega-menu ul li p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
}

.header .mega-product {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 38px;
}

.product-box {
    width: 100px;
    text-align: center;
    margin-top: 30px;
    overflow: hidden;
    border-radius: 50%;
    height: 100px;

}

.multy-slider .product-box {
    margin-top: 0 !important;
    margin: 0 auto;
}

.product_image {
    width: 100px;
}

.product_image h6 {
    font-weight: 600;
    font-size: 15px;
    color: #0f1111;
    line-height: 150%;
    margin-top: 10px;
    text-align: center;
}

.border-right-cos {
    border-right: 1px solid #E0E0E0;
}

.border-bottom-cos {
    border-bottom: 1px solid #E0E0E0;
}


.new_in_model .modal-dialog {
    margin: 60px auto !important;
    max-width: 800px !important;
}

.new_in_model .modal-content {
    background: #FFFFFF !important;
    border: 1px solid #CCCCCC !important;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08) !important;
    border-radius: 16px !important;
}

.new_in_model .modal-body {
    padding: 10px 30px 30px 30px;
}

@media (max-width: 1863px) {
    .new_in_model .new-in-main {
        justify-content: initial;
    }
}

.new_in_model .new-in-box1,
.new_in_model .new-in-box2,
.new_in_model .new-in-box3,
.new_in_model .new-in-box4,
.new_in_model .new-in-box5,
.new_in_model .new-in-box6 {
    margin-top: 20px;
}

.new_in_model .sky-text {
    position: absolute;
    top: 50%;
    transform: translate(0, -60%);
    left: 8%;
}

.new_in_model .sky-text a {
    background: #E02E24 !important;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    border: none;
    margin-top: 7px;
    padding: 8px 18px;
    display: block;
}

.new_in_model .sky-text a:hover {
    background-color: #b3221a !important;
}

.new_in_model .sky-text h5 {
    font-weight: 900;
    font-size: 22px;
    color: #0f1111;
}

.new_in_model .sky-text h5 span {
    font-weight: 400;
}

.new_in_model h5 {
    font-weight: 800;
    font-size: 17px;
    color: #0f1111;
}

.new_in_model ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.new_in_model ul li {
    margin-top: 5px;
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
}

.new_in_model .new-in-box2 a {
    font-weight: 700;
    font-size: 15px;
    color: #E02E24;
    margin-top: 5px;
}

.new-in-box4 h5,
.new-in-box5 h5 {
    margin-top: 15px;
}

.text-red {
    color: #E02E24 !important;
}

.new-in-box6 .new-in-last {
    display: flex;
    flex-wrap: wrap;
}

.price-text {
    width: 100px;
}

@media (max-width: 1700px) {
    .header {
        column-gap: 1rem !important;
    }
}

@media (max-width: 1600px) {
    .header {
        padding: 10px 15px;
    }
}

@media (max-width: 1400px) {
    .header {
        column-gap: 20px !important;
    }
}

@media (max-width: 1500px) {
    .header .search-main .nav-link {
        display: none;
    }
}

@media (max-width: 1140px) {
    .header .menu-box ul {
        column-gap: 20px;
    }
}

@media (max-width: 1024px) {
    .price-text {
        display: none;
    }

    .menu-box {
        display: none;
    }

    .header .toggle {
        display: block;
        display: flex;
    }

    .new_in_model .modal-dialog {
        margin: 12px !important;
    }

    .new_in_model .modal-dialog {
        max-width: 100% !important;
    }

}

@media (max-width: 600px) {

    .new_in_model .new-in-box1,
    .new_in_model .new-in-box2,
    .new_in_model .new-in-box3,
    .new_in_model .new-in-box4,
    .new_in_model .new-in-box5,
    .new_in_model .new-in-box6 {
        width: 100%;
    }

    .sky-box {
        margin-right: 0 !important;
        width: 100%;
    }

    .top-cos {
        margin-top: 10px !important;
    }
}

@media (max-width: 512px) {

    .header .dropdown button,
    .cart,
    .toggle {
        padding: 0 5px;
    }

    /* .search-main {
        display: none !important;
    } */

    .header {
        justify-content: space-between;
    }
}

@media (max-width: 425px) {
    .header {
        column-gap: 6px !important;
    }
}

/* header end */

/* mobile menu start */
.mobile-menu .modal-dialog {
    max-width: 100%;
    margin: 12px;
}

.mobile-menu .menu-main ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-menu .close-modal-btn {
    position: absolute;
    top: -10px;
    right: 0;
}

.mobile-menu .menu-main ul li a {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: none;
}

.mobile-menu .menu-main ul li a.active {
    color: #E02E24;
}

.mobile-menu .show-menu {
    position: absolute;
    top: -38px;
    right: 0;
    background-color: transparent;
    outline: none;
    border: none;
}

.mobile-menu .sub-catagories {
    border: none;
    padding: 10px;
    background-color: rgba(211, 211, 211, 0.336);
    border-radius: 5px;
}

.mobile-menu .sub-catagories .accordion-item:first-child li {
    margin-top: 0 !important;
}

.mobile-menu .show-body-menu .sub-catagories li {
    font-weight: 600;
    font-size: 14px;
    color: #0f1111;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px !important;
    width: 100%;
}



.mobile-menu .accordion-item .accordion-button {
    padding-bottom: 0 !important;
    border: none !important;
}

.mobile-menu .accordion-button::after {
    display: none;
}

.mobile-menu .accordion-body {
    padding: 15px 0;
}

.mobile-menu .accordion-item .accordion-button:not(.collapsed) img {
    transform: rotate(-90deg);
}

.mobile-menu .accordion-item {
    background-color: transparent !important;
    border: none !important;
}

.mobile-menu .mobile-menu-cat-box .cate-box {
    width: 150px;
}

.mobile-menu .mobile-menu-cat-box .cat-img-round {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d3d3d35c;
    border-radius: 50%;
    overflow: hidden;
}

.mobile-menu .mobile-menu-cat-box .cate-box img {
    width: 75%;
}

.mobile-menu .mobile-menu-cat-box .cate-box h5 {
    font-size: 14px;
    font-weight: 600;
    color: #0f1111;
}

.mobile-menu {
    padding-left: 0 !important;
}

/* mobile menu end */


/* hero start */
.hero {
    border-radius: 10px;
    overflow: hidden;
}

.selling-banner {
    display: flex;
    align-items: center;
    background-position: 10% bottom;
    background-position: center;
}

.selling-banner img {
    width: 100%;
}

.hero-banner .hero-text {
    position: absolute !important;
    left: 10% !important;
    top: 50% !important;
}


.hero .hero-text {
    position: relative;
    left: 14%;
}

.hero .hero-text h1 {
    font-family: 'Afternoon in Stereo Personal Us';
    font-weight: 400;
    font-size: 80px;
    color: #FFFF;
}

.hero .hero-text p {
    font-weight: 600;
    font-size: 35px;
    color: #FFFF;
    margin-top: -12px;
}

.shop-btn {
    background: #E02E24 !important;
    border-radius: 5px;
    padding: 0 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    border: none;
    margin-top: 20px;
}

.shop-btn svg {
    font-size: 20px;
}

.hero .shipping-time {
    position: absolute;
    top: 20px;
    left: 5.5%;
}

.hero .shipping-time h6 {
    font-weight: 700;
    font-size: 17px;
    color: #99FF16;
}

.hero .shipping-time p {
    font-weight: 700;
    font-size: 13px;
    color: #FFFFFF;
}

.time span {
    width: 28px;
    height: 28px;
    background-color: #fff;
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
    color: #0f1111;
    display: flex;
    align-items: center;
    justify-content: center;
}

.time p {
    color: #fff;
}

@media (max-width: 668px) {
    .hero .hero-text {
        left: 8%;
    }

    .hero,
    .selling-banner {
        background-position: 85% bottom;
    }

    .selling-banner {
        background-position: 20% bottom;
    }

    .hero .hero-text h1 {
        font-size: 60px;
    }

    .hero .hero-text p {
        font-size: 30px;
    }
}

@media (max-width: 425px) {
    .hero .hero-text h1 {
        font-size: 48px;
    }

    .hero .hero-text p {
        font-size: 20px;
    }
}

/* hero end */

/* sale start */
.sale .sale-banner {
    background-image: url("../img/sale.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
}

.shop {
    background-image: url("../img/shop-banner.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    border-radius: 10px !important;
}

.shop h4 {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.sale .fire h5 {
    font-style: italic;
    font-weight: 700;
    font-size: 25px;
    color: #FFFFFF;
}

.sale h4 {
    font-weight: 700;
    font-size: 28px;
    color: #FFFFFF;
}

.sale .sale-time label,
.sale .sale-time svg {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.sale .sale-time button {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none;
    padding: 0;
}

.sale .sale-time button svg {
    font-size: 25px;
}

.toolbar .tools {
    background: #F9F9F9;
    border-radius: 6px;
    flex-wrap: wrap;
}

.toolbar .tool-box {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    border-right: 1px solid #E6E6E6;
    margin: 20px 0;
}

.toolbar .tool-box:last-child {
    border: none;
}

.toolbar .tool-box span {
    font-weight: 700;
    font-size: 16px;
    color: #0f1111;
}

@media (max-width: 1140px) {
    .sale .sale-banner {
        flex-direction: column;
        row-gap: 30px;
        background-position: center center;
    }
}

@media (max-width: 425px) {
    .toolbar .tools {
        flex-direction: column;
    }

    .toolbar .tool-box {
        border-right: none;
    }
}

/* sale end */

/* Discount offer start */

.discount-offer {
    background-color: #ecf4f8;
    text-align: center;
    color: #0f1111;
}

.discount-card {
    border-right: 1px solid #C0D1D4;
}

@media (max-width: 991px) {
    .mid-border-none {
        border: none;
    }
}

@media (max-width: 576px) {
    .discount-card {
        border: none;
    }
}

.discount-card h1 {
    font-weight: 600;
}

.discount-card .discount-btn {
    background: #E02E24 !important;
    border-radius: 24px;
    padding: 8px 20px;
    color: white !important;
    font-size: 16px;
    border: none;
}

/* Discount offer end */


/* product-slider start */
.product-card {
    border-radius: 6px;
    background-color: #FFF9F9;
    overflow: hidden;
}

.product-slider .product-card {
    width: 100%;
}

.product-card h5 {
    font-weight: 700;
    font-size: 16px;
    color: #0f1111;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95%;
}

.product-card img {
    width: 100%;
}

.per {
    font-weight: 700;
    font-size: 13px;
    color: #E02E24;
    margin-top: 8px;
}
.top-nav {
    display: none;
}
.per span {
    font-size: 13px;
}

.sub-per {
    font-weight: 700;
    font-size: 13px;
    color: #e02d2465;
}

.gray {
    color: #9098B1;
    font-weight: 700;
}

del {
    font-weight: 400;
    font-size: 13px;
    color: #9098B1;
}

.per-with-off {
    font-weight: 700;
    font-size: 13px;
    color: #FB7181;
}

.swiper-button-next,
.swiper-button-prev {
    width: 42px !important;
    height: 42px !important;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 27%);
    right: 2px !important;
}

.swiper-button-prev {
    left: 2px !important;
}

.swiper-button-next,
.swiper-button-prev {
    --swiper-navigation-size: 16px !important;
    font-weight: bolder !important;
    color: #E02E24 !important;
}

.slider-controls-cos {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 99;
}

.slider-controls-cos button {
    width: 42px !important;
    height: 42px !important;
    background-color: #fff !important;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 27%);
    right: 2px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    color: #FB7181 !important;
    padding: 0;
}

@media (max-width: 512px) {
    .slider-controls-cos .next-slide-cos {
        position: absolute;
        right: -14px !important;
    }

    .slider-controls-cos .prev-slide-cos {
        position: absolute;
        left: -14px !important;
    }
}

/* product-slider end */

/* section4 start */
.section-4 .product-s4-card {
    border-radius: 15px;
    overflow: hidden;
}

.section-4 .product-s4-card .card-text-bottom {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

/* .section-4 .product-s4-card .card-text-bottom-daily-drops {
    position: absolute;
    bottom: 20px;
    width: 100%;
} */

.section-4 .product-s4-card img {
    width: 100%;
}

.section-4 .product-s4-card .card-text h5 {
    font-weight: 700;
    font-size: 20px;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}

.card-text {
    width: 50%;
}

.section-4 .product-s4-card .card-text span {
    font-weight: 500;
    font-size: 15px;
    color: #000;
}

.section-4 .big-box {
    left: 3%;
    top: 50%;
    transform: translate(0px, -50px);
}

.section-4 .big-box-daily-drops {
    left: 67%;
    top: 91%;
    transform: translate(0px, -50px);
}

.section-4 .big-box h5 {
    font-weight: 400;
    font-size: 36px !important;
}

.section-4 .big-box span {
    font-weight: 400;
    font-size: 25px !important;
}

@media (max-width: 1200px) {
    .section-4 .big-box h5 {
        font-size: 28px !important;
    }

    .section-4 .big-box span {
        font-size: 16px !important;
        margin: 0 !important;
    }

    .section-4 .big-box {
        left: 5%;
    }

    .section-4 .big-box-daily-drops {
        left: 59%;
        top: 94%;

    }
}

/* section4 end */


.home-first-image {
    margin: 0px 0 40px 0px;
}

.home-second-image {
    margin: 40px 0;
}

.home-second-image .shop-the-drop {
    background: #E42E67 !important;
    border-radius: 5px;
    padding: 0 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    position: absolute;
    bottom: 28%;
    left: 9%;
    font-size: 20px;
}

@media (max-width: 1200px) {
    .home-second-image .shop-the-drop {
        padding: 0 20px;
        height: 32px;
        font-size: 16px;
    }
}

@media (max-width: 700px) {
    .home-second-image .shop-the-drop {

        padding: 0 13px;
        height: 23px;
        font-size: 12px;
    }
}

/* categories start */
.categories {
    margin: 100px 0;
}

.title h2 {
    font-weight: 800;
    font-size: 32px;
    color: #0f1111;
    width: fit-content;
    margin: 0 auto;
}

.title h2 span {
    position: relative;
}

.title h2 span::before {
    position: absolute;
    content: "";
    width: 64px;
    height: 64px;
    background: rgba(224, 46, 36, 0.18);
    border-radius: 50%;
    z-index: -1;
    top: -14px;
    left: -30px;
}

.title h2 span {
    font-size: 42px !important;
}

.categories .categories-card {
    background: #FFFFFF;
    border: 2px solid #EBF0FF;
    border-radius: 100px;
}

.categories .categories-card img {
    border-radius: 50%;
}

.categories .cate-text h6 {
    font-weight: 700;
    font-size: 20px;
    color: #0f1111;
}

.categories .cate-text p {
    font-weight: 700;
    font-size: 17px;
    color: #E02E24;
    margin-top: 8px;
}

.categories .categories-slider-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    background-color: #d3d3d35c;
    border-radius: 50%;
    overflow: hidden;
}

.categories .categories-slider-img img {
    width: 74% !important;
    aspect-ratio: 3/4;
    object-fit: cover;
}

.stylist-card {
    border-radius: 15px;
    overflow: hidden;
}

.stylist-card1 {
    border-radius: 15px;
    overflow: hidden;
}

.stylist-card .discount {
    padding: 0px 6px 2px;
    background-color: #FFF2F2 !important;
    box-shadow: none !important;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #223263 !important;
    font-size: 20px;
    font-weight: 600;
    /* display: flex;
    flex-direction: column; */
    border: 0;
}

.insta {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    position: absolute;
    bottom: 0px;
    font-size: 35px;
    right: 0px;
}

.stylist-card .desc {
    padding: 0px 15px;
    background-color: #FFF9F9;
}

.stylist-card .title {
    color: #0f1111;
    font-weight: 700;
    padding-top: 7px;
    font-size: 16px;
}

.stylist-card .group-price {
    color: #E02E24;
    font-weight: 700;
    margin-top: 5px;
    font-size: 13px;
}

.stylist-card .individual-price {
    color: rgba(224, 46, 36, 0.4);
    font-size: 13px;
}

.stylist-card .stock {
    color: #0f1111;
}

.stylist-card .progress {
    background-color: rgba(224, 46, 36, 0.4);
    height: 5px;
}

.stylist-card .progress .progress-bar {
    background-color: #E02E24;
}

.stylist .explore-card {
    width: 100%;
}

@media (max-width: 768px) {
    .title h2 {
        font-size: 26px;
    }

    .sale h4 {
        flex-direction: column;
        row-gap: 15px;
    }
}

/* categories end */

/* explore start */
.explore {
    margin: 100px 0;
}

.explore .explore-card,
.tabs-cos .explore-card {
    width: 280px;
}

.categories .explore-card {
    width: 215px;
}

.explore .btns {
    display: flex;
    align-items: center;
    column-gap: 15px;
    overflow: auto;
}

.explore .btns-home {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.explore-main {
    display: flex;
    align-items: flex-start;
    column-gap: 15px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.explore .btns button {
    border: 2px solid #EBF0FF !important;
    border-radius: 100px;
    padding: 10px 20px;
    background-color: transparent !important;
    font-weight: 700;
    font-size: 16px;
    color: #223263 !important;
    white-space: nowrap;
    outline: none;
}

.explore .btns button.active {
    border: 2px solid #7594F3 !important;
}

@media (max-width: 425px) {
    .explore .btns button {
        padding: 5px 15px;
    }
}

.explore .btns-home button {
    border: 2px solid #EBF0FF !important;
    border-radius: 100px;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 16px;
    color: white !important;
    white-space: nowrap;
    outline: none;
}

.explore-card {
    width: 260px;
    margin-top: 20px;
}

.star-main img {
    width: 20px !important;
}

/* explore end */

/* footer start */
.footer {
    background-color: #071234;
    padding: 50px 0;
}

.footer .footer-box {
    width: 250px;
    margin-top: 30px;
}

.footer .footer-box h5,
.cards-all h5 {
    font-weight: 700;
    font-size: 18px;
    color: #7E97E2;
}

.footer .footer-box a {
    color: #fff;
    margin-bottom: 8px;
    text-decoration: none;
    display: block;
}

.footer .footer-working {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer .last {
    text-align: center;
    margin-top: 80px;
    color: #fff;
}

@media (max-width: 650px) {
    .footer .last {
        margin-top: 50px;
    }

    .cards-all img {
        width: 100%;
    }
}

.pointer {
    cursor: pointer;
}

/* footer end */

/* shop-categories start */
.shop-categories,
.basket,
.tabs-cos,
.get-set {
    margin: 100px 0;
}

.shop-categories .cate-box {
    width: 213px;
    margin-top: 30px;
}

@media (max-width: 610px) {
    .cate-box {
        width: 45% !important;
    }

    .cate-main .cat-img-round {
        width: 100% !important;
    }

    .shop-categories .cate-main {
        gap: 18px !important;
    }

    .shop-categories .cate-box h5 {
        font-size: 15px !important;
        margin-top: 10px !important;
    }
}

.shop-categories .cate-main {
    row-gap: 20px !important;
}

.cate-main .cat-img-round {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 220px;
    background-color: #d3d3d35c;
    border-radius: 50%;
    overflow: hidden;
}

.shop-categories .cate-box h5 {
    font-weight: 700;
    font-size: 22px;
    color: #0f1111;
}

.shop-categories .cate-box img {
    border-radius: 10px;
    width: 75%;
    aspect-ratio: 3/4;
    object-fit: cover;
}

/* shop-categories end */

/* basket start */
.basket .basket-box {
    background-color: #FFF8E7;
    background-image: url("../img/icone-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 25px;
    text-align: center;
    border-radius: 15px;
    height: 100%;
}

.basket .basket-box h5 {
    font-weight: 700;
    font-size: 22px;
    color: #0f1111;
}

.basket .basket-box button {
    margin-top: 0 !important;
}

.tabs-cos .tabs-content ul {
    border-bottom: 2px solid #D9D9D9;
}

.tabs-cos .nav-tabs .nav-link {
    font-weight: 700;
    font-size: 20px;
    color: #0f1111;
    opacity: 0.5;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    height: 100%;
}

.tabs-cos .nav-tabs .nav-link.active {
    border-bottom: 2px solid #000;
    font-weight: 700;
    font-size: 20px;
    color: #0f1111;
    opacity: 1;
}

.tabs-cos .nav-tabs .nav-link.hover {
    border-color: transparent !important;
}

.get-set .get-box {
    overflow: hidden;
    border-radius: 15px;
}

.get-set .get-box img {
    width: 100%;
}

.get-box .per-box-get {
    background: #FFE2E2;
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.get-box .per-box-get h5 {
    font-weight: 500;
    font-size: 22px;
    color: #0f1111;
}

.hot .hot-box {
    border-radius: 0;
}

.hot .hot-box img {
    object-fit: contain !important;
    width: auto !important;
}

.hot .hot-text {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 5%;
}

.hot .hot-text h5 {
    font-weight: 700;
    font-size: 40px;
    color: #0f1111;
}

.hot .hot-text h5 span {
    font-size: 25px;
}

.hot .hot-text p {
    color: #fff;
    font-size: 20px;
    margin-left: 10px;
}

.hot-sub-box h5 {
    font-size: 60px !important;
}

.hot-sub-box .hot-text {
    left: 12%;
}

.white-text {
    color: #fff !important;
}

@media (max-width: 1140px) {
    .hot-sub-box h5 {
        font-size: 40px !important;
    }
}

@media (max-width: 540px) {
    .get-box .per-box-get {
        flex-direction: column;
        row-gap: 20px;
    }

    .get-box .per-box-get h5 {
        font-size: 20px;
    }
}

@media (max-width: 425px) {
    .hot-sub-box h5 {
        font-size: 30px !important;
    }

    .hot .hot-text p {
        font-size: 16px;
    }
}

.owl-carousel .owl-item .product-box img {
    width: 100%;
}

.owl-carousel .item {
    padding: 15px;
}

.categories .fill-title h5 {
    font-weight: 800;
    font-size: 25px;
    color: #0f1111;
}

.categories .filter-option {
    background: #FFF8F8;

}

.sticky-filter {
    position: sticky;
    top: 100px;
}

.categories .filter-box h5 {
    font-weight: 800;
    font-size: 18px;
    color: #0f1111;
}

.categories .filter-box .accordion-item,
.accordion-button {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    box-shadow: none !important;
}

.accordion-button {
    border-bottom: 1px solid #ECDFDF !important;
    padding-bottom: 20px;
}

.accordion-button::after {
    background-image: none !important;
    color: #223263 !important;
    content: "+";
    flex-shrink: 0;
    font-size: 25px;
    height: 0;
    margin-left: auto;
    position: absolute;
    right: 32px;
    top: -5px;
    -webkit-transform: rotate(0) !important;
    transform: rotate(0) !important;
    width: 0;
}

.accordion-button:not(.collapsed)::after {
    background-image: none !important;
    content: "-";
    font-size: 40px;
    position: absolute;
    right: 32px;
    top: -14px;
}

.mt-20 {
    margin-top: 20px;
}


.check-options {
    margin-top: 8px;
}

.check-options label {
    margin-left: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
    cursor: pointer;
}

.check-options input {
    appearance: none;
    position: relative;
    cursor: pointer;
}

.check-options input::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    border: 2px solid #223263;
    border-radius: 31px;
    top: 0px;
}

.check-options input::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    top: 5px;
    left: 5px;
    background: #223263;
    border-radius: 50%;
    display: none;
}

.check-options input:checked:after {
    display: block;
}

.add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #223263 !important;
    cursor: pointer;
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-top: 15px;
    margin-left: 5px;
}

.color-btn {
    background: transparent !important;
    box-shadow: none;
    border: none;
}

.cat-color {
    padding: 2px;
}

.cat-color.active {
    border: 1px solid #000;
}

.min-max-per .per-box-min {
    border: 1px solid #C5C5C5;
    border-radius: 100px;
    width: fit-content;
    padding: 3px 10px;
    overflow: hidden;
}

.min-max-per .per-box-min input {
    width: 50px;
    border: none;
    outline: none;
}





/* basket end */


/* product-info start */
.page-path {
    flex-wrap: wrap;
}

.page-path a,
.page-path svg {
    font-weight: 700;
    font-size: 15px;
    color: #0f1111;
    opacity: 0.5;
    white-space: nowrap;
}

.page-path svg {
    font-weight: bold;
    font-size: 20px;
}

.page-path a.active {
    opacity: 1;
}

.product-info .pro-def h6 {
    font-weight: 500;
    font-size: 30px;
    color: #0f1111;
}

.product-info .brand p {
    font-weight: 600;
    font-size: 17px;
    color: #0f1111;
}

.product-info .brand p span {
    color: #9199B1;
}

.product-info .per-pro h3 {
    font-weight: 700;
    font-size: 16px;
    color: #0f1111;
}

.product-info .per-pro del {
    font-weight: 400;
    font-size: 16px;
    color: #9098B1;
}

.product-info .per-pro span {
    font-weight: 700;
    font-size: 16px !important;
    color: #FB7181;
}

.product-info .interest {
    font-weight: 700;
    font-size: 18px;
    color: #9098B1;
    display: flex;
    align-items: center;
    column-gap: 5px;
    flex-wrap: wrap;
}

.product-info .interest img {
    width: 70px;
}

.product-info .interest span {
    color: #0f1111;
    font-size: 20px;
}

.product-info .order-time {
    background: #fcebea;
    border-radius: 4px;
    padding: 8px 15px;
}

.product-info .order-time h5 {
    font-weight: 700;
    font-size: 16px;
    color: #0f1111;
}


.product-info .order-time-cos span {
    font-weight: 700;
    font-size: 14px;
    color: #0f1111;
}

.product-info .product-color h5 {
    font-weight: 700;
    font-size: 16px;
    color: #0f1111;
}

.product-info .product-color button,
.categories .product-color button {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    padding: 0;
    padding: 2px;
    border: 1px solid transparent;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-info .product-color button.active,
.categories .product-color button.active {
    border: 1px solid #000;
}

.qty select {
    border: 0.6px solid #C5C5C5;
    border-radius: 3px;
    width: 100px;
    height: 30px;
}

.product-info .size button {
    border: 1px solid lightgray;
    color: #000 !important;
    border-radius: 5px;
    padding: 6px 15px;
    font-weight: 600;
}

.product-info .size button.active {
    border: 1px solid #000;
}

.cart-items-count {
    width: 18px;
    height: 18px;
    background-color: #E02E24;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -7px;
    right: -10px;
}


.add-cart-items {
    background: #ffd814 !important;
    color: #000000;
    border-radius: 30px;
    padding: 0;
    border: none;
    box-shadow: none;
    width: 200px;
    height: 48px;
    width: 100% ;
}
.add-cart-sub {
    font-weight: 400;
    font-size: 12px;
    vertical-align: middle;
}

.add-cart-items:hover,
.add-cart-items:focus,
.add-cart-items:active {
    color: #000000 !important;
}

@media (max-width: 512px) {
    .add-cart-items {
        width: 100% !important;
    }
}

.product-info .stock span {
    font-weight: 500;
    font-size: 16px;
    color: #0f1111;
}

.product-info .stock p {
    font-weight: 500;
    font-size: 16px;
    color: #E02E24;
}

.info-title {
    font-weight: 700;
    font-size: 18px;
    color: #0f1111;
}

.sub-title-info span {
    font-weight: 700;
    font-size: 16px;
    color: #0f1111;
}

.sub-title-info span svg {
    font-size: 20px;
}

.sub-title-info span p {
    display: inline;
    font-size: 8px;
}

.product-info .order-box {
    background: #F9F9F9;
    border-radius: 6px;
    padding: 15px;
}

.product-info .order-box h5 {
    font-weight: 600;
    font-size: 17px;
    color: #009806;
}

.product-info .order-box span {
    font-weight: 400;
    font-size: 16px;
    color: #9098B1;
}

.product-info .order-box p {
    font-weight: 400;
    font-size: 15px;
    color: #0f1111;
}

.product-info .shipping-def {
    padding-bottom: 10px;
    flex-wrap: wrap;
    row-gap: 15px;
}

.product-info .security-line {
    font-weight: 400;
    font-size: 16px;
    color: #009806;
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.product-info .security-line svg {
    font-size: 20px;
}

.product-info .shipping-def ul {
    display: flex;
    padding: 0;
    margin-bottom: 0;
    margin-left: 30px;
    column-gap: 50px;
    /* margin-top: 15px; */
}

.product-info .shipping-def ul li {
    font-weight: 400;
    font-size: 16px;
    color: #0f1111;
}

.product-info .description span {
    width: 100%;
    display: block;
    font-weight: 400;
    font-size: 16px;
    color: #0f1111;
}
.product-desc-p {
    width: 100% !important;
    display: block !important;
    /* font-weight: 400 !important; */
    /* font-size: 16px !important; */
    /* color: #0f1111 !important; */
    text-decoration: none;
    background-color: transparent !important;
    border: none !important;
    color: #0f1111b3 !important;
    padding: 0 !important;
    font-weight: 600 !important;
    /* white-space: nowrap !important; */
    font-size: 15px !important;
}
.product-info .copy-btn {
    background-color: transparent !important;
    border: 1px solid #223263;
    border-radius: 100px;
    font-weight: 400;
    font-size: 14px;
    color: #223263 !important;
    padding: 5px 20px;
}
.bottom-nav {
    display: none;
}
.product-info .copy-div {
    width: fit-content;
}

.border-right-cos {
    border-right: 1px solid #E0E0E0;
}

.product-info .review h5 {
    width: fit-content;
    padding-right: 15px;
    font-size: 16px;
}

.product-info .review .rate span {
    font-weight: 600;
    font-size: 16px;
    color: #e02e24;
}

.half-rating-read label:hover {
    color: yellow !important;
}

.product-info .verified span {
    font-weight: 400;
    font-size: 12px;
    color: #0f1111;
}

.product-info .no-review a {
    font-weight: 600;
    font-size: 15px;
    color: #0f1111;
    margin-top: 5px;
    text-decoration: none;
}

.product-info .no-review a svg {
    font-size: 20px;
    margin-top: -2px;
}

.review select {
    border: 1px solid #EBF0FF;
    padding: 8px 30px 8px 8px;
    border-radius: 5px;
    font-size: 15px;
    color: #9098B1;
    font-weight: 500;
    outline: none;
    appearance: none;
    background-image: url('../img/review.png');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: calc(100% - 10px) 16px, calc(100% - 20px) 14px, 100% 0;
    cursor: pointer;
}

@media (max-width: 830px) {
    .review-responsive {
        flex-direction: column;
        row-gap: 12px;
        align-items: flex-start !important;
    }

    .filter-review {
        flex-wrap: wrap;
    }
}

.product-info .frequently button {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    color: #223263 !important;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.product-info .frequently button svg {
    font-size: 20px;
}

.product-info .slide-box img {
    width: 100%;
}

.product-info .right-mark {
    width: 20px !important;
    position: absolute;
    top: 15px;
    left: 15px;
}

.product-info .slide-box button {
    border: 1px solid #C5C5C5 !important;
    border-radius: 100px;
    padding: 5px 20px;
    font-weight: 400;
    font-size: 14px;
    color: #223263 !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.product-info .slide-box h5 {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
}

.product-info .slide-box del {
    font-size: 14px;
    color: #9098B1;
}

.product-info .slide-box span {
    font-weight: 400;
    font-size: 13px;
    color: #9098B1;
}

.product-info .add-items {
    background: #E02E24 !important;
    border-radius: 5px;
    padding: 10px 50px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border: none;
    column-gap: 5px;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
}

@media (max-width: 668px) {
    .product-info .add-items {
        padding: 8px 20px;
        font-size: 14px;
        width: 100%;
    }
}

.product-info .modal-body {
    padding-bottom: 50px;
}

@media (max-width: 512px) {
    .product-info .order-time {
        flex-direction: column;
        row-gap: 15px;
    }

    .product-info .interest,
    .product-info .brand p,
    .cos-title {
        font-size: 15px !important;
    }

    .product-info .add-cart {
        width: 100%;
    }
}

@media (max-width: 425px) {
    .product-info .add-items {
        flex-direction: column;
    }
}

@media (max-width: 360px) {
    .product-info .shipping-def ul {
        margin-left: 20px;
        flex-direction: column;
    }
}

.recent-view {
    margin-top: 100px;
}

@media (max-width: 991px) {
    .recent-view {
        margin-top: 50px;
    }
}

.recent-view h4 {
    font-weight: 700;
    font-size: 20px;
    color: #0f1111;
}

.product-info .explore-card {
    width: 285px;
}

.cart-modal .modal-dialog {
    max-width: 1180px;
}

.cart-modal .modal-content {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.count-product {
    border: 0.6px solid #C5C5C5;
    border-radius: 3px;
    display: flex;
}

.count-product button,
.count-product span {
    color: #0f1b3f !important;
    width: 30px;
    height: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: transparent !important;
    border: none;
}

.count-product span {
    border-left: 1px solid #C5C5C5;
    border-right: 1px solid #C5C5C5;
    margin: 0px !important;
}

.cart-modal .show-more {
    background-color: transparent !important;
    box-shadow: none;
    border: none;
    font-weight: 600;
    font-size: 14px;
    color: #223263 !important;
    padding: 0;
}

.cart-modal .show-more svg {
    font-size: 20px;
}

.close-modal-btn {
    background: #f9f9f9 !important;
    border-radius: 4px;
    border: none;
    box-shadow: none;
    padding: 0;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E02E24 !important;
    font-size: 20px;
}

.cart-canvas {
    width: 490px !important;
    z-index: 99999;
}

.cart-side-close {
    position: absolute;
    right: 0px;
    top: 0px;
}

.cart-side .cart-header h5 {
    font-weight: 600;
    font-size: 18px;
    color: #0f1111;
}

.cart-canvas .right-green-mark {
    width: 17px;
}

.cart-canvas .order-time h5 {
    font-size: 15px;
}

.cart-canvas .cart-items-text h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
}

.cart-canvas .cart-items-text span {
    font-weight: 600;
    font-size: 15px;
    color: #9199B1;
    margin: 5px 0;
    display: block;
}

.cart-canvas .cart-per h5 {
    font-weight: 700;
    font-size: 16px;
    color: #0f1111;
}

.cart-canvas .cart-per del {
    font-weight: 400;
    font-size: 16px;
    color: #9098B1;
}

.cart-canvas .go-cart {
    margin-top: 25px;
    background: #ffd814 !important;
    color: #000000;
    border-radius: 5px;
    padding: 8px 20px;
    border: none;
    box-shadow: none;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
}

.cart-canvas .purchased-list h4 {
    font-weight: 600;
    font-size: 18px;
    color: #0f1111;
}

.cart-canvas .cart-side-btn {
    background-color: transparent !important;
    border: none;
    box-shadow: none !important;
    padding: 0;
}


.carousel-indicators .carousel-indicator {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

.carousel-indicators .carousel-indicator.active {
    opacity: 1;
}

@media (max-width: 1200px) {
    .product-info .modal-dialog {
        margin: 20px;
    }
}

/* product-info end */

/* cart start */
.cart-main .cart-items {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #E0E0E0;
}


.cart-main .cart-items-def h5 {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.cart-main .cart-items-def span {
    font-weight: 600;
    font-size: 15px;
    color: #9199B1;
    display: block;
    margin: 3px 0;
}

.cart-main .cart-items-def p {
    background: #fcebea;
    border-radius: 4px;
    font-weight: 600;
    font-size: 15px;
    color: #0f1111;
    width: fit-content;
    padding: 8px 15px;
}

.cart-main .items-per h5 {
    font-weight: 800;
    font-size: 18px;
    color: #0f1111;
}

.cart-main .items-per del {
    font-weight: 400;
    font-size: 16px;
    color: #9098B1;
}

.cart-main .items-per span {
    font-weight: 700;
    font-size: 16px;
    color: #FB7181;
}

.cart-main .total-list {
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.cart-main .total-list label {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
}

.total h5 {
    font-weight: 800;
    font-size: 17px;
    color: #0f1111;
}

.cart-main .total-list del {
    font-weight: 500;
    font-size: 16px;
    color: #9098B1;
}

.cart-main .total-list span {
    font-weight: 800;
    font-size: 16px;
    color: #FB7181;
}

.cart-main .total-list h5 {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
}

.checkout {
    background-color: #E02E24 !important;
    border-radius: 5px !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    border: none;
    padding: 10px 0;
}

.wrap-cos {
    flex-wrap: wrap;
}

.all-total span {
    font-weight: 800 !important;
    font-size: 16px !important;
    color: #223263 !important;
}

.all-total label {
    font-weight: 800 !important;
}

.sub-total h5 {
    color: #0f1111;
    font-weight: 600;
    font-size: 17px;
}

.sub-total span {
    color: #E02E24;
    font-weight: 600;
    font-size: 17px;
}

.cart-product-img img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.cart-product-img span {
    width: 22px;
    height: 22px;
    background-color: #E02E24;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -7px;
    right: -10px;
}

.back-btn {
    background-color: transparent !important;
    color: #223263 !important;
    font-weight: 600;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
}

.contact-details {
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    padding: 20px;
}

.contact-details label {
    font-weight: 600;
    color: #0f1111;
    white-space: nowrap;
}

.contact-details p {
    color: #9199B1;
    font-weight: 500;
}

.contact-details p span {
    color: #0f1111;
}

.payment h5 {
    color: #0f1111;
    display: block;
    font-size: 16px;
    font-weight: 600;
}

.payment p {
    color: #9199B1;
    margin-top: 5px;
}

.card-main {
    border: 1px solid #d3d3d3;
    overflow: hidden;
    border-radius: 8px;
}

.card-main .card-title {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.card-main .card-title {
    background-color: rgba(243, 246, 252, 1);
}

.lock {
    background-image: url('../img/lock.png');
    background-position: calc(100% - 14px) 12px, calc(100% - 20px) 14px, 100% 0;
    background-repeat: no-repeat;
    background-size: 17px;
}

.alert {
    background-image: url('../img/alert.png');
    background-position: calc(100% - 14px) 15px, calc(100% - 20px) 14px, 100% 0;
    background-repeat: no-repeat;
    background-size: 17px;
}

@media (max-width: 660px) {
    .cart-main .cart-items {
        flex-direction: column;
        row-gap: 10px;
    }
}

@media (max-width: 425px) {

    .cart-product-img img {
        width: 80px !important;
        height: 80px !important;
    }

    .cart-main .cart-items-def h5 {
        font-size: 14px;
    }

    .cart-main .cart-items-def span {
        font-size: 12px;
    }

    .cart-main .items-per h5 {
        font-size: 16px;
    }
}

@media (max-width: 414px) {
    .marg-cos {
        margin: 15px 0;
    }
}

/* cart end */

/* profile start */
.profile .nav-pills .nav-link {
    padding: 0;
    font-weight: 600;
    font-size: 15px;
    color: #0f1111;
    display: flex;
    align-items: center;
    column-gap: 12px;
    position: relative;
    padding-left: 15px;
    height: 40px;
    text-decoration: none;
}

.profile .nav-pills .nav-link.active {
    background-color: #fdefee;
    position: relative;
}

.profile .nav-pills .nav-link.active::before {
    width: 3px;
    height: 100%;
    background: #E02E24;
    content: "";
    position: absolute;
    left: 0;
}

.profile .select-img input {
    display: none;
}

.profile .preview {
    width: fit-content;
}

.profile .preview .preview_profile {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.profile .file-label {
    position: absolute;
    bottom: -2px;
    right: -5px;
    cursor: pointer;
}

.input-filed label {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
}

.input-filed input,
.input-filed textarea {
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    width: 100%;
    margin-top: 5px;
    padding: 10px 15px;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    color: #0f1111;
}

.input-filed textarea::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    color: #0f1111;
    opacity: 0.8;
}

.w-fit {
    width: fit-content;
}

.input-filed .gender label {
    white-space: nowrap;
    margin-bottom: -2px;
    font-weight: 500;
    font-size: 14px;
    color: #0f1111;
}

input[type='radio'] {
    height: 20px;
    width: 20px;
    vertical-align: middle;
}

.profile .save-btn {
    background: #E02E24 !important;
    border-radius: 5px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    border: none;
    padding: 10px 0;
    margin-top: 25px;
}

.order-list .nav-tabs {
    border: none !important;
    margin-bottom: 0 !important;

}

.order-list .nav-tabs .nav-link {
    border: none !important;
    font-weight: 600;
    font-size: 15px;
    color: #9199B1 !important;
}

.order-list .nav-tabs .nav-link.active {
    position: relative;
    color: #223263 !important;
    background-color: transparent !important;
}


.order-list .nav-tabs .nav-link.active::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 3px;
    border-radius: 50px;
    bottom: 0;
    left: 50%;
    background-color: #0f1111;
    transform: translate(-50%, 0px);
}

.order-list .tab-content {
    margin-top: 40px;
    min-height: 400px;
}

.order-list .search-order {
    position: absolute;
    border: 1px solid #EBF0FF;
    border-radius: 5px;
    top: -5px;
    right: 0;
    padding: 10px 15px;
    width: 400px;
}

.order-list .search-order input,
.order-input input {
    border: none;
    outline: none;
}

.order-list .search-order input::-webkit-input-placeholder {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #9098B1;
}

.order-input input::-webkit-input-placeholder {
    font-weight: 600;
    font-size: 14px;
    color: #0f1111;
}

.found p {
    font-weight: 600;
    font-size: 18px;
    color: #0f1111;
    white-space: pre-wrap;
}

.found p img {
    width: 30px !important;
}

.found span {
    font-weight: 500;
    font-size: 15px;
    color: #9199B1;
}

.find-your-order label {
    font-weight: 600;
    font-size: 15px;
    color: #0f1111;
    width: 100%;
}

.order-input {
    border: 1px solid #E02E24;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
    width: 100%;
    padding: 8px 10px;
}

.h-400 {
    min-height: 400px;
}

.input-style {
    border: 1px solid #EBF0FF;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    color: #9098B1;
    padding: 10px 15px;
    outline: none;
}

.offer-input input {
    width: 300px !important;
}

.offer-input input::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    color: rgb(34, 50, 99, 0.3);
}

.apply-btn {
    border: 1px solid rgba(107, 107, 107, 0.7) !important;
    border-radius: 100px;
    font-weight: 600;
    font-size: 16px;
    color: #000000 !important;
    background-color: transparent !important;
    padding: 8px 20px;
    box-shadow: none !important;
}

.balance-main h5 {
    font-weight: 700;
    font-size: 20px;
    color: #0f1111;
}

.total-usd label {
    font-weight: 600;
    font-size: 16px;
    color: #9199B1;
}

.total-usd h3 {
    font-weight: 700;
    font-size: 28px;
    color: #0f1111;
}

.total-usd {
    border-bottom: 1px solid #E0E0E0;
}

.history-table p {
    font-weight: 700;
    font-size: 18px;
    color: #0f1111;
}

.history-table td,
.history-table th {
    padding-left: 0;
}

.history-table th {
    font-weight: 700;
    font-size: 17px;
    color: #0f1111;
}

.history-table td {
    font-weight: 500;
    font-size: 16px;
    color: #9199B1;
}

.location-main button {
    font-weight: 600;
    font-size: 17px;
    color: #223263 !important;
    background-color: transparent !important;
    border: none;
    padding: 0;
}

.location-main .address-box {
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    padding: 10px 20px;
}

.address-box h5 {
    font-weight: 700;
    font-size: 15px;
    color: #0f1111;
    display: flex;
    align-items: center;
}

.address-box h5 span {
    font-weight: 600;
    font-size: 15px;
    color: #9199B1;
    margin-left: 10px;
}

.address-box p {
    font-weight: 500;
    font-size: 14px;
    color: #0f1111;
}

.address-box label {
    font-weight: 500;
    font-size: 14px;
    color: #8F98B4;
    margin-left: 22px;
}

.address-box input::before {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    border: 2px solid #223263;
    border-radius: 31px;
    top: -8px;
}

.address-box input::after {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    top: -4px;
    left: 4px;
    background: #223263;
    border-radius: 50%;
    display: none;
}

.copy-main {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.copy-main button,
.copy-main span {
    font-weight: 500;
    font-size: 14px;
    color: #8F98B4 !important;
}

.security-main h5 {
    font-weight: 600;
    font-size: 18px;
    color: #0f1111;
}

/* .security-main p {
    font-weight: 500;
    font-size: 14px;
    color: #0f1111;
} */

/* .security-main button {
    background: #E02E24 !important;
    border-radius: 3px;
    font-weight: 500;
    font-size: 15px;
    border: none;
    padding: 5px 15px;
    color: #FFFFFF;
} */

.third-party h4 {
    font-weight: 700;
    font-size: 20px;
    color: #0f1111;
}

.third-party h5 {
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #4E5B82 !important;
}

.security-main .linked {
    background: #ffff !important;
    color: #E02E24 !important;
    font-weight: 500;
    font-size: 15px;
    padding: 0;
}

.delete-account {
    font-weight: 600;
    font-size: 16px;
    color: #223263 !important;
    background-color: transparent !important;
    border: none;
    padding: 0;
    margin-top: 40px;
}

.delete-account svg {
    font-size: 20px;
}

.list-not p {
    font-weight: 500;
    font-size: 16px;
    color: rgb(122, 132, 161, 0.6);
    margin-top: 5px;
}

.receive {
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    padding: 20px 15px;
}

.receive h5 {
    font-weight: 700;
    font-size: 17px;
    color: #0f1111;
}

.receive p {
    color: rgb(122, 132, 161, 0.8);
    font-weight: 500;
    font-size: 14px;
}

.receive p a {
    display: inline-block;
    text-decoration: underline;
}

.number {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    height: 42px;
}

.number select {
    height: 100%;
    border: none;
    outline: none;
    padding: 0 30px 0 10px;
    border-right: 1px solid #D0D0D0;
    font-weight: 500;
    font-size: 14px;
    color: #0f1111;
    appearance: none;
    background-image: url('../img/country-code.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 18px, calc(100% - 20px) 14px, 100% 0;
}

.number input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0 15px;
}

.number input::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    color: rgb(122, 132, 161, 0.6);
}

.submit-btn-receive {
    background: #E02E24 !important;
    border-radius: 5px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #FFFFFF !important;
    padding: 6px 20px !important;
}

@media (max-width: 1500px) {
    .order-list .search-order {
        width: 300px;
    }
}

@media (max-width: 1300px) {

    .order-list .search-order {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .order-list .tab-content {
        min-height: auto;
    }

    .h-400 {
        min-height: 250px;
    }
}

@media (max-width: 340px) {
    .address-box h5 {
        flex-direction: column;
        align-items: start;
    }

    .address-box h5 span {
        margin-left: 0;
    }
}

.add-address h5 {
    font-weight: 700;
    font-size: 24px;
    color: #0f1111;
    text-align: center;
}

.add-address .modal-dialog {
    max-width: 700px;
}

.add-address .modal-dialog .modal-content {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
    border-radius: 11px;
}

/* profile end */


/* particular-categories-page start */

/* .home-first-image .particular-cate-head {
    position: absolute;
    top: 42px;
    left: 42px;
} */

.home-first-image .Shop-Care-Bears-div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ebf8fe;
    padding: 76px 0px;
    margin-top: -96px;
}

/* .home-first-image .particular-cate-head h1 {
    font-weight: 900;
    font-size: 75px;
    color: #0f1111;
    line-height: 80px;
}

.home-first-image .particular-cate-head h1 span {
    font-size: 52px;
}

.home-first-image .particular-cate-head h1 p {
    color: #ffff;
    margin-left: 58%;
}

@media (max-width: 1600px) {
    .home-first-image .particular-cate-head h1 {
        font-size: 55px;
        line-height: 55px;
    }
}

@media (max-width: 1300px) {
    .home-first-image .particular-cate-head h1 {
        display: flex;
        align-items: flex-end;
        column-gap: 10px;
        flex-wrap: wrap;
    }

    .home-first-image .particular-cate-head h1 br {
        display: none;
    }

    .home-first-image .particular-cate-head h1 p {
        margin-left: 0px;
    }
}

@media (max-width: 1024px) {
    .home-first-image .particular-cate-head h1 {
        font-size: 40px;
    }

    .home-first-image .particular-cate-head h1 span {
        font-size: 30px;
    }

    .home-first-image .particular-cate-head {
        top: 10px;
        left: 17px;
    }
}

@media (max-width: 425px) {
    .home-first-image .particular-cate-head h1 {
        font-size: 22px;
        column-gap: 5px;
    }

    .home-first-image .particular-cate-head h1 span {
        font-size: 18px;
    }

    .home-first-image .particular-cate-head {
        top: 0px;
        left: 17px;
    }
} */

.blue-bg {
    background-color: #ecf8fe;
}

.sec-2 .shop-care-bears p {
    font-weight: 400;
    font-size: 38px;
    color: #0f1111;
    font-family: 'Ardina Script';
}

.shop-care-bears-btn {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    background: #E02E24 !important;
    border-radius: 5px;
    padding: 8px 40px;
    border: none;
    margin-top: 20px !important;
}

.shop-box h2 {
    font-family: 'Brume';
    position: absolute;
    bottom: 50px;
    -webkit-text-stroke: #fff;
    text-shadow:
        -2px 0px 0px rgb(255, 255, 255),
        2px 0px 0px rgb(255, 255, 255),
        0px -2px 0px rgb(255, 255, 255),
        0px 2px 0px rgb(255, 255, 255);
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, 0px);
}

.brown {
    color: #5C3100;
}

.dark {
    color: #0B1E1B;
}

.brown-light {
    color: #5C3100;
    bottom: 70px !important;
}

.green-text {
    color: #1C5E05;
}

.pink-slider {
    padding: 0 50px;
}

.pink-card {
    border-radius: 5px;
    overflow: hidden;
}

.pink-card h5 {
    font-weight: 700;
    font-size: 16px;
    color: #0f1111;
    margin-top: 3px;
}

.pink-card p {
    font-weight: 400;
    font-size: 13px;
    color: #0f1111;
    margin-top: 2px;
}

.like-btn {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0;
}

.pink-card .pink-card-text {
    background-color: #fff;
}

.pink-bg {
    background: rgb(249, 195, 239);
    background: linear-gradient(180deg, rgba(249, 195, 239, 1) 0%, rgba(177, 131, 253, 1) 100%);
}

.view-all-btn {
    background: #223263 !important;
    padding: 8px 50px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    border: none;
}

.view-all-btn:hover {
    background: #162142 !important;
}

.view-btn-yellow {
    background-color: #FFD43E !important;
}

.view-btn-yellow:hover {
    background-color: #d1af33 !important;
}

.view-btn-red {
    background-color: #E02E24 !important;
}

.view-btn-red:hover {
    background-color: #a52b25 !important;
}

.green-bg {
    background: rgb(114, 187, 245);
    background: linear-gradient(180deg, rgba(114, 187, 245, 1) 0%, rgba(116, 250, 167, 1) 100%);
}

.particular-product-items h5 {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
}

.particular-product-items p,
.particular-product-items p span {
    color: #223263 !important;
}

.particular-product-items span {
    color: #9098B1;
}

@media (max-width: 1400px) {
    .sec-2 .shop-care-bears p {
        font-size: 30px;
    }
}

@media (max-width: 768px) {
    .sec-2 .shop-care-bears p {
        font-size: 22px;
    }

    .pink-big-img {
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .pink-slider {
        padding: 20px !important;
    }
}

@media (max-width: 678px) {

    .explore .explore-card,
    .tabs-cos .explore-card {
        width: 47% !important;
    }

    .product-card h5 {
        font-size: 15px;
    }

    .per,
    .sub-per {
        font-size: 14px;
    }

    .space-card {
        padding: 10px !important;
    }

    .tabs-cos .nav-tabs .nav-link,
    .tabs-cos .nav-tabs .nav-link.active {
        font-size: 13px;
    }
}

/* particular-categories-page end */

/* For you start */
.for-you {
    height: calc(100vh - 100px);
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 80px;
}

.product-items {
    position: absolute;
    left: 10%;
    top: 520px;
}

.product-items p {
    font-weight: 700;
    font-size: 14px;
    color: #0f1111;
}

.reel-items {
    position: absolute;
    width: 110px;
    right: 60px;
    bottom: 200px;
}

.reel-items p {
    font-weight: 700;
    font-size: 11px;
    color: #FFFFFF;
    background-color: #dc3545;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
}

.for-you del {
    font-weight: 700;
    font-size: 13px;
    color: #9098B1;
    margin-top: 10px;
    display: block;
}

.for-you .reels-box {
    width: 430px;
    height: calc(100vh - 150px);
    background-color: #000000;
    border-radius: 10px;
}

.for-you .reels-box .reels-img {
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.for-you .items-box {
    background-color: #EBF0FF;
    border-radius: 6px;
    width: 130px;
}

.mobile-header {
    display: none !important;
}

@media (max-width: 668px) {
    .mobile-header {
        display: block !important;
    }
    .for-you .items-box {
        width: 90px;
    }

    .reel-items p {
        font-size: 11px;
    }
    .top-nav {
        display: block;
        z-index: 90;
        background-color: #000;
    }
}

.for-you .items-box img {
    width: 100%;
}

.for-you .user-name {
    position: absolute;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.for-you .price {
    position: absolute;
    bottom: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    width: 100%;
}

.for-you .additional-icon {
    position: absolute;
    bottom: 200px;
    width: 50px;
    right: 12px;
}
.for-you .for-you-product-card {
    position: absolute;
    /* bottom: 509px; */
    width: 50px;
    right: 60px;
    left: 20px;
    bottom: 200px;

}

.for-you .cart-btn-reels {
    position: absolute;
    bottom: 0px;
    width: 50px;
    left: -85px;
}

.for-you .cart-btn-reels button {
    background: #E02E24 !important;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 8px 15px;
}

.for-you .dropdown-toggle::after {
    display: none;
}

.user-name p {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
}

.user-name span {
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
    display: block;
    margin-top: -2px;
}
.go-live-btn {
    position: absolute;
    top: 40px;
    right: 20px;
}

.follow-btn {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    background: #FF8300 !important;
    border-radius: 5px;
    border: none;
}

@media (max-width: 668px) {
    .follow-btn {
        font-size: 12px;
    }
    .go-live-btn{
        right: 10px;
        top: 70px;
    }
}

.price button:first-child {
    background-color: rgb(224, 46, 36, 0.5) !important;
}

.price button {
    width: 154.53px;
    background: #E02E24 !important;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.additional-box {
    /* background: #F9F9F9; */
    border-radius: 5px;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.additional-box button {
    background-color: transparent !important;
    border: none;
    padding: 0;
    box-shadow: none;
    font-weight: 600;
    font-size: 16px;
    /* color: #223263 !important; */
    color: #ffffff !important;
}

@media (max-width: 1300px) {
    .product-items {
        left: 2%;
    }
}

@media (max-width: 1030px) {
    .product-items {
        display: none;
    }
}

@media (max-width: 676px) {
    .for-you .additional-icon {
        bottom: 140px;
        right: 0px;
    }
    .for-you .for-you-product-card {
        left: 20px;
        bottom: 130px;
    }
    .reel-items {
        width: 110px;
        right: 60px;
        bottom: 68px;
    }

    .for-you .cart-btn-reels {
        bottom: 12px;
        left: 12px;
    }
}

@media (max-width: 512px) {
    .for-you {
        row-gap: 20px;
        padding: 0px;
    }
    .reels-box {
        height: calc(100vh - 85px) !important;
        background-color: #000000 !important;
        border-radius: 0px !important;
    }
}

.product-color-cos button {
    background-color: transparent !important;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    overflow: hidden;
    color: #000 !important;
}

.product-color-cos button img {
    object-fit: cover;
    width: 100%;
    transform: scale(1.5);
}

.product-color-cos button.active {
    border: 1px solid #000;
}

.for-you .swiper {
    width: 100%;
    height: 100%;
}

.for-you .swiper-slide {
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


.for-you .reels-box video {
    border-radius: 10px;
}

@media (max-width: 676px) {
    .for-you .swiper {
        width: 100%;
    }
}


.for_you-modal .modal-dialog {
    max-width: 445px;
    margin: auto;
}

@media (max-width: 640px) {

    .product-list-modal .modal-dialog {
        margin: auto 15px;
    }

}

@media (max-width: 480px) {

    .for_you-modal .modal-dialog {
        margin: auto 15px;
    }

}

.for_you-modal .modal-dialog .modal-content {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
    border-radius: 11px;
}

.for_you-modal h5 {
    font-weight: 800;
    font-size: 25px;
    color: #0f1111;
    text-align: center;
}

.for_you-modal .show-all-comments {
    height: 300px;
    overflow: auto;
}

.for_you-modal .show-all-comments p {
    font-weight: 400;
    font-size: 18px;
    color: #0f1111;
    opacity: 0.4;
}

.for_you-modal .show-all-comments ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}

.for_you-modal .show-all-comments ul li:first-child {
    margin-top: 0;
}

.for_you-modal .show-all-comments ul li {
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
    margin-top: 10px;
}

.for_you-modal .comment-user {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.for_you-modal .comment-user img {
    object-fit: cover;
}

.for_you-modal .comments-user-name h6 {
    font-weight: 700;
    font-size: 16px;
    color: #0f1111;
}

.for_you-modal .comments-user-name span {
    color: #3b3b3bbd;
    display: block;
    line-height: 20px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: -webkit-box; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.for_you-modal .sent-comment input {
    background: #EEEEEE;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    padding: 0 42px 0 15px;
}

.for_you-modal .sent-comment-icon {
    position: absolute;
    top: 4px;
    right: 12px;
    background-color: transparent !important;
    border: none;
    color: #0B1E1B !important;
    font-size: 20px;
    padding: 0;
}

.for_you-modal .for-you-product-img {
    width: 138px;
    height: 138px;
    object-fit: cover;
    border-radius: 5px;
}

.for_you-modal .for-you-product-text h6 {
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.product-list-modal .modal-dialog {
    max-width: 600px;
}

.product-list-modal .price button {
    width: 100%;
}

.product-list-scroll {
    max-height: 420px;
    overflow: auto;
}

@media (max-width: 485px) {
    .for_you-modal .for-you-product-img {
        width: 90px;
        height: 126px;
    }

    .product-list-modal .price button {
        font-size: 12px;
    }

    .product-list-modal .mobile-row {
        flex-direction: row;
    }

    .product-list-modal .price {
        column-gap: 5px !important;
    }
}

/* For you end */

.welcome-modal .modal-dialog {
    max-width: 440px;
    margin: auto;
}

.welcome-modal h5 {
    font-weight: 700;
    font-size: 22px;
    color: #0f1111;
}


.welcome-modal p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #9199B1;
}

.welcome-modal button {
    background-color: transparent !important;
    border: none;
    padding: 0;
}

.welcome-modal button img {
    width: 100%;
}

@media (max-width: 480px) {
    .welcome-modal .modal-dialog {
        max-width: 440px;
        margin: auto 15px;
    }

    .welcome-modal h5 {
        font-size: 19px;
    }

    .welcome-modal p {
        font-size: 16px;
    }

    .welcome-modal p br {
        display: none;
    }
}


/* product slider */

.image-gallery-slide-wrapper {
    width: 100%;
}

.image-gallery-thumbnail {
    border-radius: 10px;
    overflow: hidden;
}

.image-gallery-thumbnail:hover {
    border: 1px solid #223263 !important;
}

.image-gallery-thumbnail.active {
    border: 1px solid #223263 !important;
}

.image-gallery-right-nav svg,
.image-gallery-left-nav svg {
    display: none;
}

.image-gallery-right-nav {
    width: 60px;
    height: 60px;
    background-image: url("../img/thum-arrow.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0;
}

.image-gallery-left-nav {
    width: 60px;
    height: 60px;
    background-image: url("../img/thum-left-arrow.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    /* max-height: 100%; */
    height: 760px !important;
    object-fit: cover !important;
}

.image-gallery-thumbnail {
    width: 80px;
    height: 80px;
}

@media (max-width: 1280px) {
    .product-img-slider-cos .image-gallery-thumbnails .image-gallery-thumbnails-container {
        display: block;
    }
}

@media (max-width: 991px) {
    .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
        margin-top: 10px;
    }
}

.add-to-card-btn {
    background-color: #fff !important;
    position: absolute;
    color: #000 !important;
    border: none;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    transition: 0.5s;
    z-index: -1;
    opacity: 0;
    box-shadow: 0 0 10px lightgray;
}

.overflow-hidden {
    overflow: hidden;
}

.pink-card:hover .add-to-card-btn,
.product-card:hover .add-to-card-btn,
.product-card :hover .add-to-card-btn {
    bottom: 10px;
    z-index: 999;
    opacity: 1;
}

/* @media (max-width: 600px) {
    .cos-width {
        width: 100% !important;
    }
} */
@media (max-width: 650px) {

    .categories .explore-card,
    .product-info .explore-card {
        width: 47%;
    }
}

.price-range-slider .rc-slider-track {
    background-color: #0f1111;
}

.rc-slider-handle {
    background-color: #FEBDBD;
    border-color: #FEBDBD;
    width: 16px;
    height: 16px;
    top: 3px;
    opacity: 1;
}

.rc-slider-handle:hover,
.rc-slider-handle:active {
    border-color: #FEBDBD;
    box-shadow: 0 0 5px #FEBDBD;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #FEBDBD !important;
    box-shadow: 0 0 5px #FEBDBD !important;
}

.rs-slider-bar {
    background-color: #9090902e;
}

.rs-slider-bar,
.rs-slider-progress-bar {
    height: 4px;
}

.rs-slider-handle:hover::before,
.rs-slider-handle:focus::before {
    box-shadow: 0 0 0 8px #febdbd6e !important;
}

.Individual-per button,
.Individual-per button:first-child {
    background-color: rgb(224, 46, 36, 0.7) !important;
}

.Individual-per button.active {
    background-color: rgb(224, 46, 36, 1) !important;
}

.active-catagories {
    border: 2px solid #223263;
}

.cate-slider-box img {
    width: 100%;
}

.cate-slider-box h6 {
    font-weight: 700;
    font-size: 18px;
    color: #0f1111;
    min-height: 40px;
}

.cate-slider-box p {
    font-weight: 700;
    font-size: 16px;
    color: #E02E24;
    margin-top: 10px;
}

@media (max-width: 425px) {
    .cate-slider-box img {
        width: 65% !important;
        margin: 0 auto;
    }
}

/* login */
.login-box {
    max-width: 425px;
    margin: 0 auto;
}

.register-box {
    max-width: 900px;
    padding: 0 50px;
}

.login-register h3,
.register-box h3 {
    font-weight: 700;
    font-size: 23px;
    color: #0f1111;
}

.login-input label {
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: #0f1111;
}

.login-input input,
.login-input select,
.login-input textarea {
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    padding: 10px 15px;
    width: 100%;
    margin-top: 5px;
    outline: none;
    color: #0f1111;
}


.select-arrow {
    appearance: none;
    background-image: url('../img/select-bg.png');
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) 21px, calc(100% - 20px) 14px, 100% 0;
}

.login-input input::-webkit-input-placeholder,
.login-input textarea::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    color: rgb(34, 50, 99, 0.8);
}

.show-hide-pass {
    position: absolute;
    bottom: 10px;
    right: 15px;
    background-color: transparent !important;
    border: none;
    padding: 0;
    box-shadow: none;
    color: #b3b3b3 !important;
    font-size: 22px;
    display: flex;
}

.check-terms label {
    font-weight: 400;
    font-size: 15px;
    color: #0f1111;
    text-align: left;
}

.check-terms label a {
    display: inline-block;
    color: #E02E24;
    text-decoration: none;
}

.submit-btn {
    background: #E02E24 !important;
    border-radius: 5px;
    height: 42px;
    font-weight: 500;
    font-size: 15px;
    color: #fff !important;
    border: none;
    box-shadow: none;
    margin-top: 30px;
}

.pass-error {
    position: absolute;
}

.reset-pass {
    background: transparent !important;
    font-weight: 400;
    font-size: 14px;
    border: none;
    box-shadow: none;
    color: #223263 !important;
    padding: 0;
}

.footer-sec .register-link {
    background: transparent !important;
    font-weight: 400;
    font-size: 15px;
    border: none;
    box-shadow: none;
    color: #223263 !important;
    display: block;
}

.footer-sec .register-link a {
    color: #E02E24;
    text-decoration: underline;
}

.or-sec .line {
    width: 100%;
    height: 2px;
    background: #D9D9D9;
}

.or-sec span {
    font-weight: 600;
    font-size: 15px;
    color: #BBBBBB;
}

.border-right-cos-login {
    border-right: 2px solid #d8d8d859;
}

.register-box .react-tel-input .form-control {
    padding: 9.5px 14px 6.5px 60px;
    width: 100%;
}

.register-box .react-tel-input .country-list {
    overflow-x: hidden;
}

.spacing-cos {
    padding: 50px 15px;
}

.forgot-pass .modal-content {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.pass-model-title h3 {
    font-weight: 700;
    font-size: 20px;
    color: #0f1111;
}

.pass-model-title p {
    font-weight: 500;
    font-size: 18px;
    color: #818BAB;
}

.forgot-pass-close {
    position: absolute;
    top: -3px;
    right: -3px;
}

.otp input {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 2px solid #D0D0D0;
    outline: none;
    text-align: center;
    color: #E02E24;
    font-weight: bold;
    font-size: 20px;
}

.otp input::-webkit-outer-spin-button,
.otp input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

/* Firefox */
.otp input[type=number] {
    appearance: textfield;
}

@media (max-width: 767px) {
    .border-right-cos-login {
        border-right: none;
    }

    .login-box {
        max-width: 100%;
    }

    .register-box {
        padding: 0;
    }

    .spacing-cos {
        padding: 20px 15px 50px 15px;
    }
}

.register-main {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*error message */
.error {
    color: #E02E24;
    font-size: 15px;
}

.errorAdmin {
    color: #E02E24;
    font-size: 15px;
    text-align: left;
    margin-top: 10px;
}

.truncate-after-words {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95%;
}

/* color */

.colors {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;

}

.loading {
    height: calc(100vh - 100px);
}

.loading svg {
    width: 320px !important;
}


.shop-btn-up {
    z-index: 9;
}

.shop-now {
    background-color: #fff !important;
    position: absolute;
    color: #000 !important;
    border: none;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    transition: 0.5s;
    z-index: -1;
    opacity: 0;
    z-index: -1;
    box-shadow: 0 0 10px lightgray;
}

.shop-btn-up:hover .shop-now {
    opacity: 1;
    z-index: 99;
    bottom: 30px;
}

.app-download img {
    width: 150px;
}

@media (max-width: 425px) {
    /* .mobile-row {
        flex-direction: column;
    } */

    .mobile-row button {
        width: 100%;
    }

    .app-download img {
        width: 120px;
    }
}

/* review start */
.review .review-box h5 {
    font-weight: 500;
    font-size: 18px;
    color: #0f1111;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.review .review-box .date_pro_info {
    font-weight: 400;
    font-size: 15px;
    margin: 8px 0;
    display: block;
    color: #9098B1;
}

.review .review-items-def {
    border-bottom: 1px solid #E0E0E0;
}

.review .review-box p {
    font-weight: 600;
    font-size: 15px;
    color: #0f1111;
}

.review .review-like button {
    background-color: transparent !important;
    padding: 0 !important;
    color: #071234 !important;
    border: none;
    font-size: 20px;
    box-shadow: none !important;
}

.review .color-def {
    row-gap: 5px;
    column-gap: 25px;
}

@media (max-width: 512px) {
    .review-box {
        flex-direction: column;
    }

    .review-img {
        width: 100%;
    }
}

/* review end */

.empty {
    color: #22326392;
    font-size: 18px;
    font-weight: 500;
}

.coupne-code {
    background-color: #009806;
    padding: 5px 15px;
    font-size: 15px !important;
    border-radius: 50px;
    color: #fff !important;
    width: fit-content;
}

.coupne-code button {
    background-color: transparent !important;
    padding: 0;
    border: none;
    box-shadow: none;
    font-size: 20px;
    margin-top: -4px;
}


/* wishlist start */
.wishlist .title-wishlist {
    border-bottom: 1px solid #E0E0E0;
}

.wishlist .title-wishlist h4 {
    font-weight: 800;
    font-size: 20px;
    color: #0f1111;
}

.wishlist .wishlist-sell span {
    font-weight: 500;
    font-size: 13px;
    color: #0f1111;
    display: block;
}

.wishlist .share-del-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ffffffbe;
    padding: 5px 8px;
    border-radius: 5px;

}

.wishlist .share-del-btn button {
    background-color: transparent !important;
    border: none;
    padding: 0;
    font-size: 20px;
    height: fit-content;
    display: flex;
}

.wishlist .share-del-btn button:first-child {
    color: #223263 !important;
}

.wishlist .share-del-btn button:last-child {
    color: #E02E24 !important;
}

.submit-btn:hover,
.shop-btn:hover,
/* .add-cart-items:hover, */
.btn-scroll-top:hover,
.shop-care-bears-btn:hover,
.checkout:hover {
    background-color: #b3221a !important;
}


.header .account .login-btn:hover {
    background-color: #b3221a !important;
}

/* wishlist end */

.myprofile {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover !important;
    border-radius: 50%;
}



/* about start */
.about .banner-left-box {
    width: 550px;
    margin-top: 30%;
    margin-left: 10%;
}

.about .banner-left-box h1 {
    font-weight: 800;
    color: #000;
    font-size: 60px;
    line-height: 70px;
}

.red-color {
    color: #e02e24 !important;
}

.download-ui p {
    color: #232233 !important;
    font-size: 25px;
    margin-top: 20px;
    font-weight: 600;
}

@media (max-width: 1600px) {
    .about .banner-left-box {
        margin-left: 0;
    }

    .about .banner-left-box h1 {
        font-size: 50px;
        line-height: 60px !important;
    }
}

@media (max-width: 991px) {
    .about .banner-left-box {
        margin-left: 0;
        margin-top: 0;
        text-align: center;
        width: 100%;
    }

    .about .app-download {
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .about .banner-left-box {
        width: 100%;

    }

    .download-ui p {
        font-size: 15px !important;
    }

    .about .banner-left-box h1 {
        font-size: 30px;
        line-height: 38px !important;
    }
}

.clubmall-work .clubmall-work-text h2 {
    font-size: 50px;
    color: #000;
    font-weight: 800;
}

.clubmall-work .clubmall-work-text p {
    font-size: 24px;
    color: #757095;
    margin-top: 20px;
}

.clubmall-work {
    margin-top: 100px;
}

@media (max-width: 991px) {
    .clubmall-work .clubmall-work-text {
        text-align: center;
    }

    .clubmall-work {
        margin-top: 50px;
    }

    .space-top {
        margin-top: 0 !important;
    }
}

@media (max-width: 600px) {
    .clubmall-work .clubmall-work-text h2 {
        font-size: 40px;
    }

    .clubmall-work .clubmall-work-text p {
        font-size: 16px;
        margin-top: 10px;
    }
}

@media (max-width: 425px) {
    .clubmall-work .clubmall-work-text h2 {
        font-size: 24px;
    }
}

/* .round-bg {
    background-image: url("../img/pink-box-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 100px;
} */

.policy h3,
.policy h5 {
    color: #000;
}

.policy p,
.policy ul li span {
    color: #323f4b;
}

.policy ul li {
    color: #e02e24;
}


.policy h3 {
    font-weight: 600;
    text-align: center;
}

.policy h5 {
    font-weight: 600;
    margin-top: 20px;
}

.policy a {
    display: inline-block;
}




.download-section .section-texts {
    padding: 120px 50px;
    background: rgba(224, 46, 36, .4);
    border-radius: 50px;
}

.download-section .download-app-partial {
    position: relative;
    z-index: 1;
}

.download-section .section-texts {
    background-image: url("../img/bg-bottom-left.png");
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: auto 86%;
    position: relative;
}

.download-section .section-texts:after,
.download-section .section-texts:before,
.download-section .section-texts .top-right-bg {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.download-section .section-texts::after {
    background: url("../img/bg-bottom-right.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 16% auto;
    z-index: 0;
}

.download-section .section-texts::before {
    background: url("../img/bg-top-left.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 16% auto;
    z-index: 0;
}

.download-section .section-texts .top-right-bg {
    background-image: url("../img/bg-top-right.png");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: auto 86%;
}

.download-section .section-title {
    line-height: 65px;
    position: relative;
    z-index: 1;
    max-width: 880px;
    margin: 0 auto 40px;
    font-size: 50px;
    color: #000;
    font-weight: 700;
}

.pink-box {
    margin-top: 100px;
}

@media (max-width: 991px) {
    .download-section .section-title {
        font-size: 40px;
        line-height: 55px;
    }

    .download-section .section-texts {
        padding: 50px 20px;
    }
}

@media (max-width: 600px) {
    .download-section .section-title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .download-section .section-texts::before,
    .download-section .section-texts::after {
        display: none;
    }

    .pink-box {
        margin-top: 50px;
    }

    .download-section .section-texts {
        border-radius: 20px;
    }
}

/* about end */

.address-shipped {
    border: 1px solid lightgray;
    padding: 20px;
    border-radius: 8px;
}

.address-shipped h6 {
    color: #071234;
    font-weight: 600;
}

.address-shipped p {
    font-size: 16px !important;
}

.change-add {
    background-color: transparent !important;
    border: none;
    color: rgb(0, 68, 255) !important;
    font-weight: 600;
    padding: 0;
    font-size: 15px;
}

.contact-title h1 {
    font-size: 50px;
    font-weight: 600;
    color: #0f1111;
}

.contact-title p {
    font-size: 22px;
    font-weight: 600;
}

.contact {
    max-width: 800px;
    margin: 0 auto;
}

@media (max-width: 425px) {
    .contact-title h1 {
        font-size: 30px;
    }

    .contact-title p {
        font-size: 16px;
    }
}

.wishlist-btn {
    border: none;
    padding: 0;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 99;
    width: 50px;
    height: 50px;
    background-color: #E02E24 !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

@media (max-width: 668px) {
    .wishlist-btn {
        bottom: 78px;
        font-size: 15px;
    }
}

.wishlist-btn-cos {
    border: none;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
    width: 40px;
    height: 40px;
    background-color: #FFF2F2 !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.filter-show {
    display: none;
}

@media (max-width: 991px) {
    .filter-show {
        display: block;
    }
}

.spacing-top {
    margin-top: 230px;
}

@media (max-width: 668px) {
    .cate-slider-box h6 {
        font-size: 12px;
        margin-top: 10px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 28px;
    }

    .spacing-top {
        margin-top: 130px;
    }


    .like-size {
        width: 80% !important;
    }

    .cate-slider-box p {
        font-size: 10px;
        margin-top: 5px !important;
    }

    .owl-carousel .item {
        padding: 5px;
    }

    .title h2 {
        font-size: 20px;
    }

    .title h2 span {
        font-size: 33px !important;
    }

    .title h2 span::before {
        width: 50px;
        height: 50px;
        top: -9px;
        left: -18px;
    }

    .home-first-image {
        margin: 0px 0 10px 0px;
    }

    .home-second-image {
        margin: 10px 0;
    }

    .mar-cos-sale {
        margin-top: 20px !important;
    }

    .categories {
        margin: 40px 0;
    }

    .categories-slider {
        margin-top: 20px !important;
    }

    .slider-controls-cos button {
        width: 30px !important;
        height: 30px !important;
    }

    .stylist-card {
        border-radius: 0;
    }

    .stylist-card .title,
    .product-card h5 {
        font-size: 10px;
    }

    .stylist-card .group-price,
    .stylist-card .individual-price,
    .per,
    .per span,
    .sub-per {
        font-size: 9px;
        margin-top: 0;
    }

    .stylist-card .individual-price {
        margin-bottom: 8px !important;
    }

    .stylist-card .progress {
        height: 3px;
    }

    .stylist-card .stock {
        font-size: 10px;
    }

    .stylist-card .desc {
        padding: 0px 5px;
    }

    .discount p {
        font-size: 7px !important;
    }

    .discount svg {
        font-size: 16px;
    }

    .stylist-card .discount {
        right: 5px;
        top: 5px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 30px !important;
        height: 30px !important;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 11px !important;
    }

    .section-4 {
        margin-top: 10px !important;
    }

    .section-4 .big-box h5 {
        font-size: 18px !important;
    }

    .section-4 .product-s4-card .card-text span {
        font-size: 12px !important;
    }

    .section-4 .shop-btn,
    .get-set .shop-btn,
    .basket .shop-btn,
    .tab-content .shop-btn,
    .btn-cos-mobile {
        height: 35px !important;
        padding: 0 10px;
        font-size: 12px;
        column-gap: 3px;
        margin-top: 10px !important;
    }

    .section-4 .shop-btn svg,
    .get-set .shop-btn svg,
    .basket .shop-btn svg,
    .tab-content .shop-btn svg,
    .btn-cos-mobile svg {
        font-size: 16px;
    }

    .jewellery .shop-btn {
        display: none;
    }

    .mar-top-10 {
        margin-top: 10px !important;
    }

    .mar-top-20 {
        margin-top: 20px !important;
    }

    .mar-top-40 {
        margin-top: 40px !important;
    }

    .mar-bot-20 {
        margin-bottom: 20px !important;
    }

    .stylist-card1 {
        border-radius: 0;
    }

    .shop-now,
    .add-to-card-btn {
        font-size: 9px;
    }

    .explore .btns-home button {
        padding: 6px 20px;
        font-size: 12px;
    }

    .shop-care-bears-btn {
        font-size: 12px;
        margin-top: 10px !important;
        padding: 8px 20px;
    }

    .sec-2 .shop-care-bears p {
        font-size: 16px;
    }

    .pink-card .pink-card-text {
        padding: 5px !important;
    }

    .pink-card h5 {
        font-size: 15px;
    }

    .pink-card p {
        font-size: 12px;
    }

    .shop-categories,
    .basket,
    .tabs-cos,
    .get-set {
        margin: 40px 0;
    }

    .tabs-content {
        margin-top: 20px !important;
    }

    .hot .hot-text h5 {
        font-size: 24px;
    }

    .cos-height {
        height: 200px;
        border-radius: 18px !important;
        overflow: hidden !important;
    }

    .hot-sub-box .hot-text {
        left: 6%;
    }

    .mar-top-0 {
        margin-top: 0 !important;
    }

    .mar-bot-20 {
        margin-bottom: 20px !important;
    }

    .hot .hot-text h5 {
        font-size: 22px !important;
    }

    .view-all-btn {
        padding: 8px 30px;
        font-size: 14px;
    }

    .in-per {
        /* display: none; */
        /* display: none; */
    }

    .footer {
        padding: 20px 0;
    }

    .footer .footer-box h5,
    .cards-all h5 {
        font-size: 15px;
    }

    .footer .footer-box a {
        font-size: 12px;
    }

    .footer .last {
        font-size: 15px;
    }

    .header .account .login-btn {
        font-size: 13px;
    }

    .mar-bot-0 {
        margin-bottom: 0 !important;
    }

    .mobile-menu .menu-main ul li a,
    .new-in-btn {
        font-size: 14px;
    }

    .found p,
    .found button {
        margin-top: 10px !important;
    }

    .product-info .slide-box span {
        font-size: 12px;
    }

    .slider-box-per {
        padding-top: 0px !important;
    }

    .product-info .slide-box h5 {
        font-size: 16px;
    }

    .product-info .slide-box del {
        font-size: 14px;
    }

    .checkout {
        font-size: 13px;
    }

    .cart-main .term span,
    .cart-main .term p {
        font-size: 13px;
    }

    .product-info .pro-def h6,
    .review .review-box h5 {
        font-size: 15px;
    }

    .product-info .per-pro h3 {
        font-size: 24px;
    }

    .review .review-box p {
        font-size: 12px;
    }

    .recent-view h4 {
        font-size: 16px;
    }

    .product-info .per-pro h3 {
        font-size: 20px;
    }

    .product-info .per-pro span,
    .product-info .per-pro del {
        font-size: 14px;
    }

    .product-info .size button {
        font-size: 12px;
    }

    .product-info .description span,
    .product-info .shipping-def ul li,
    .product-info .security-line {
        font-size: 14px;
    }

    .product-info .security-line svg {
        font-size: 18px;
    }

    .product-info .security-line svg {
        margin-left: -5px;
        margin-top: 4px;
    }

    .info-title {
        font-size: 16px;
    }

    .header a,
    .header .drop-items h6 {
        font-size: 15px;
    }

    .security-main h5 {
        font-size: 15px;
    }

    .list-not p {
        font-size: 14px;
    }

    .security-main button {
        font-size: 12px;
    }

    .third-party h5 {
        font-size: 15px !important;
    }

    .third-party h4 {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .logo img {
        width: 30px;
    }

    .header-icon {
        width: 22px;
    }

    .myprofile {
        width: 24px !important;
        height: 24px !important;
    }

    .header .account button {
        font-size: 24px;
    }

    .account {
        gap: 15px !important;
    }

    .header {
        height: 60px;
        column-gap: 10px;
        display: none !important;
    }
    .for-you .reels-box video {
        border-radius: 0px;
    }
    .page-layout {
        /* background-color: #000000; */
    }
    .bottom-nav {
        display: block;
    }
    .search-filed {
        padding: 6px 8px;
    }

    .profile .nav-pills .nav-link {
        font-size: 14px;
    }

    input[type='radio'] {
        height: 15px;
        width: 15px;
    }

    .gap-mobile {
        gap: 15px !important;
    }

    .profile .save-btn {
        font-size: 14px;
        padding: 7px 0;
    }

    .input-filed input,
    .input-filed textarea {
        padding: 6px 10px;
    }

    .input-filed label {
        font-size: 14px;
    }

    .location-main button {
        font-size: 15px;
    }

    .location-main .address-box {
        margin-top: 10px !important;
        padding: 10px 11px;
    }

    .address-box p {
        font-size: 13px;
    }

    .copy-main button {
        font-size: 13px !important;
    }

    .address-box label {
        margin-bottom: 4px;
    }

    .total-usd {
        padding-bottom: 15px !important;
        margin-top: 15px !important;
    }

    .total-usd img {
        width: 25px;
    }

    .third-party {
        margin-top: 30px !important;
    }

    .delete-account {
        font-size: 15px;
        margin-top: 20px !important;
    }

    .security-main .linked {
        font-size: 14px;
    }

    .order-list .nav-tabs .nav-link {
        padding: 0 0 8px 0;
    }

    .order-list .nav-tabs {
        column-gap: 15px;
    }

    .found p {
        font-size: 15px;
    }

    .found img {
        width: 60px;
    }

    .order-list .search-order input::-webkit-input-placeholder {
        font-size: 13px;
    }

    .search-filed input::-webkit-input-placeholder {
        font-size: 12px;
    }

    .btn-scroll-top {
        width: 40px;
        height: 40px;
    }

    .page-path a {
        font-size: 14px;
    }

    .price button {
        font-size: 13px;
    }

    .add-cart-items {
        padding: 8px 20px;
        font-size: 14px;
    }

    .product-info .modal-body {
        padding-bottom: 10px;
    }

    .image-gallery-thumbnail {
        width: 50px;
        height: 50px;
    }

    .image-gallery-right-nav,
    .image-gallery-left-nav {
        width: 40px;
        height: 40px;
    }

    .colors,
    .wishlist-btn {
        width: 30px;
        height: 30px;
    }

    .wishlist-btn img {
        width: 18px;
    }

    .check-terms label {
        font-size: 12px;
        margin-top: 3px;
    }

    .login-input input,
    .login-input select,
    .login-input textarea {
        font-size: 14px;
    }

    .submit-btn {
        height: 37px;
        margin-top: 20px !important;
    }

    .login-input label {
        font-size: 13px;
    }

    .footer-sec .register-link,
    .reset-pass {
        font-size: 12px;
    }

    .order-list {
        padding-top: 0px;
    }

    .mar-cos {
        margin-top: 10px;
    }

    .explore {
        margin: 80px 0;
    }

    .new_in_model h5 {
        font-size: 15px;
    }

    .new_in_model ul li {
        font-size: 14px;
    }

    .found button {
        font-size: 13px;
    }
}

.sub-categories-list {
    background-color: #f6f6f6;
    padding: 24px;
}

.sub-categories-list h3 {
    font-weight: 700;
    font-size: 22px;
    color: #0f1111;
}

.sub-categories-list button {
    border: 1px solid #e5e5e5;
    padding: 4px 12px;
    cursor: pointer;
    background-color: #fff !important;
    border-radius: 0;
    color: #9199B1 !important;
    font-size: 14px;
    border-radius: 5px;
}

.sub-categories-list button.active {
    background-color: #E02E24 !important;
    border: none;
    color: #ffff !important;
}

.tabs-cos .nav-tabs {
    flex-wrap: nowrap;
}

@media (max-width: 1200px) {
    .discount-offer h1 {
        font-size: 30px;
    }

    .discount-offer p {
        font-size: 16px;
    }
}

@media (max-width: 991px) {
    .discount-offer h1 {
        font-size: 24px;
    }

    .discount-offer p {
        font-size: 14px;
    }

    .discount-card .discount-btn {
        font-size: 10px;
    }
}

@media (max-width: 768px) {
    .discount-offer h1 {
        font-size: 20px;
    }

    .discount-offer p {
        font-size: 12px;
    }

    .discount-card .discount-btn {
        padding: 8px;
    }

    .pad-cos {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media (max-width: 512px) {
    .discount-offer p {
        font-size: 8px;
    }

    .discount-offer h1 {
        font-size: 15px;
    }

    .pad-cos {
        padding: 10px 0 !important;
    }

    .discount-card .discount-btn {
        padding: 3px;
        font-size: 8px;
    }

    .discount-offer {
        padding: 0 12px;
    }
}

.order-table tr th {
    font-size: 15px;
    text-align: center;
    color: #0f1111;
    font-weight: 700;
}

.order-table tr td {
    vertical-align: middle;
    text-align: center;
}

.order-table tr td .pro-text h6 {
    color: #0f1111;
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 300px;
    text-wrap: wrap;
    line-height: 24px;
}

.order-table tr td .pro-text span {
    text-align: left;
    display: block;
    margin-top: 5px;
    font-size: 15px;
    font-weight: 600;
    color: #0f1111;
    opacity: 0.5;
}

.order-table tr td .badge {
    padding: 6px 15px;
    border-radius: 50px;
    font-size: 15px;
    font-weight: normal;
}

.order-table tr td p {
    color: #384f94;
}

@media (max-width: 668px) {
    .order-table tr th {
        font-size: 14px;
        white-space: nowrap;
    }

    .order-table tr td .pro-text h6 {
        font-size: 14px;
    }

    .order-table tr td p,
    .order-table tr td .pro-text span {
        font-size: 13px;
    }
}

.mega-menu-list {
    max-height: 400px;
    overflow: auto;
}

.mobile-together {
    display: none !important;
}

.wrap-line-cos {
    white-space: inherit !important;
}

@media (max-width: 991px) {
    .mobile-together {
        display: block !important;
    }

    .web-together {
        display: none !important;
    }
}

.wishlist-card {
    width: 240px !important;
}

@media (max-width: 938px) {
    .wishlist-card {
        width: 45% !important;
    }

    .wishlist-items {
        justify-content: center !important;
    }

    .wishlist-items .buy-now-items {
        font-size: 12px !important;
        height: 30px !important;
    }

    .mobile-spacing-card-body {
        padding: 10px !important;
    }
}

@media (max-width: 425px) {
    .wishlist .share-del-btn button {
        font-size: 14px;
    }
}

.coming-soon h4 {
    color: #E02E24 !important;
    font-weight: bold;
    font-size: 20px;
}

@media (max-width: 767px) {
    .coming-soon {
        height: 100px !important;
    }
}

@media (max-width: 991px) {
    .catagories-not-found {
        height: 200px !important;
    }
}

.mt-0-cos {
    margin-top: 0 !important;
}

.review-file input[type=file]::file-selector-button {
    border: 2px solid #E02E24;
    background-color: transparent;
    border-radius: 5px;
    color: #E02E24;
    font-size: 14px;
    padding: 2px 10px;
}

.write-review {
    background-color: transparent !important;
    border: none;
    padding: 0;
    color: #E02E24 !important;
    font-weight: 600;
}

.empty-wishlist {
    margin-top: 130px;
}

@media (max-width:512px) {
    .empty-wishlist {
        margin-top: 50px;
    }
}

.delete-comment {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-top: 0 !important;
}

.clear-all-filter {
    background-color: #E02E24 !important;
    border: none;
    color: #fff !important;
}

.text_frequently {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95%;
    display: block;
    font-size: 16px !important;
}


.cart-color h6 {
    color: #0f1111;
    font-weight: 600;
}

.cart-color span {
    color: #9199B1;
}

@media (max-width : 991px) {
    .explore-main {
        justify-content: center;
    }
}

@media (max-width : 512px) {
    .explore-main {
        justify-content: flex-start;
    }
}

.shipping-table table thead tr th,
.shipping-table table tbody tr td {
    padding: 20px;
    vertical-align: middle;
    white-space: nowrap;
}

.text-gray {
    color: gray;
}

.text-red {
    color: #E02E24;
    font-weight: 600;
}

.shipping-table p {
    color: #E02E24;
}

.nots p a {
    text-decoration: underline !important;
    color: #E02E24 !important;
}

.nots p {
    margin: 12px 0;
    color: rgb(90, 90, 90);
}

.shipping-table table thead tr {
    background-color: #f6f6f6;
}

@media (max-width: 512px) {

    .shipping-table table thead tr th,
    .shipping-table table tbody tr td {
        padding: 10px;
        font-size: 14px;
    }
}

.return-policy h5 {
    font-size: 17px;
    margin-top: 30px;
}


.return-policy p {
    font-size: 15px;
    color: gray;
}

.highlight {
    font-weight: 600;
    color: #000;
}

.thankyou p {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

.thankyou-box {
    max-width: 600px;
}

.thankyou-box h1 {
    font-weight: 900;
    color: #E02E24;
}

.thankyou-box h3 {
    font-weight: 800;
    color: #000;
    font-size: 24px;
}

.thankyou-box span {
    font-size: 100px;
    color: #009806;
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

@media (max-width: 512px) {
    .thankyou-box img {
        width: 80%;
    }
}

.delete-product {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.dot-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media (max-width: 660px) {
    .items-img {
        width: 100%;
    }

    .items-img img {
        width: 100%;
    }
}

@media (max-width: 512px) {
    .cart-list img {
        width: 120px;
        height: 120px;
        object-fit: cover;
    }

    .thankyou p {
        font-size: 16px;
    }

    .thankyou p br {
        display: none;
    }

    .thankyou-box h3 {
        font-size: 16px !important;
    }

    .thankyou-img svg {
        font-size: 70px;
    }
}

.rect-select-cos {
    margin-top: 5px;
}

.rect-select-cos input {
    height: 30px;
}

.coupon-code-text {
    background-color: #fef8f8;
    border: 1px solid #e02e24;
    padding: 14px 10px;
    border-radius: 8px;
    margin-top: 40px;
}

.coupon-code-text p {
    color: #0f1111;
    font-weight: 600;
    font-size: 15px;
}

.coupon-code-text p span {
    font-weight: 800;
    color: #e02e24;
}

.coupon-code-text .clubmalltry {
    font-weight: 700;
    margin-top: 0 !important;
}

@media (max-width: 425px) {
    .coupon-code-text p {
        font-size: 11px;
    }

    .coupon-code-text .clubmalltry {
        font-size: 10px;
    }
}


.gif_position {
    width: 200px;
}

.gif_position img {
    width: 100%;
}

@media (max-width: 512px) {
    .gif_position {
        width: 100%;
    }
}

.img-size {
    aspect-ratio: 3/4;
    object-fit: cover;
}

.terms-use-header {

    margin-top: 20px;
    /* Adjust as needed for spacing */
    font-size: 50px;
    color: #000;
    font-weight: 800;

}

.progress-bar-2 {
    margin-bottom: 30px;
}

.progress-bar-2 {
    margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .progress-bar-2 {
        margin-bottom: 20px;
        /* Adjust as needed for smaller screens */
    }
}

@media screen and (max-width: 576px) {
    .progress-bar-2 {
        margin-bottom: 10px;
        /* Adjust as needed for even smaller screens */
    }
}



/* -------------------- delete page start ------------------  */

.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 15px auto;

    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4caf50;

        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }

        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }

        &::before,
        &::after {
            content: "";
            height: 100px;
            position: absolute;
            background: #ffffff;
            transform: rotate(-45deg);
        }

        .icon-line {
            height: 5px;
            background-color: #4caf50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;

            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }

            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }

        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, 0.5);
        }

        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #ffffff;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }

    5% {
        transform: rotate(-45deg);
    }

    12% {
        transform: rotate(-405deg);
    }

    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.sesctionafter {
    width: 35%;
    margin: 0 auto;
}

@media (min-width: 320px) and (max-width: 1024px) {

    .sesctionafter {
        width: 85%;
    }
}

/* -------------------- delete page end ------------------  */

.footer-bottom {
    background-color: #0D205A !important;
    color: #fff;
    text-align: center;
    padding: 20px 20px;
}

.footer-bottom p {
    color: #fff !important;
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
}

.footer-bottom a {
    color: #fff !important;
    font-size: 16px;
    font-weight: 700;
}

.video-player-container {
    position: relative;
}

.custom-controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.voice-control {
    /* Style your button as needed */
    padding: 10px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.video-player-container {
    position: relative;
}

.custom-controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.voice-control {
    background: none;
    border: none;
    cursor: pointer;
}

.foryou-voice img {
    width: 22px;
}

/* CSS to hide the volume control button on webkit browsers */
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button {
    display: none !important;
}

.agree-checkbox {
    margin-top: 10px;
}

.agree-label {
    margin-left: 10px !important;
}

.terms-checkbox {
    margin-top: 12px;
    display: flex;
}

.termsCheckbox {
    position: relative;
    left: 10px;

}

.return-policy-checkbox {
    display: flex;
    position: relative !important;
    top: 20px !important;


}

.return-policy-label {

    font-weight: 500;
    position: relative;
    font-size: 16px;
    color: #223263 !important;
    bottom: 15px;

}

.product-heading-name {
    font-size: 24px !important;
    line-height: 32px !important;
    font-family: "Amazon Ember", Arial, sans-serif;
}

.for-margin {
    margin-top: 35px !important;
}

/* CSS */
.cm-in-buttons {
    border: 1px solid #000;
}

.size-chart-button {
    background-color: #fff;
    border: navajowhite;
    color: #000 !important;
}

.size-chart-button.active,
.size-chart-button:hover {
    background-color: red !important;
    color: #fff !important;
}

.size-chart-img {
    width: 995px;
    height: 700px;
}

.custemailbutton {
    background: white;
    color: black;
}

.custemailbutton:hover {
    background: white;
    color: black;
}

.custemailicon {
    width: 20px;
}

.footer-bottom {
    background-color: white !important;
    height: 42px;
}

.footer-bottom p {
    color: black !important;
}

.footer-bottom a {
    color: rgb(238, 51, 51) !important;
}

.custemailtext {
    position: relative;
    bottom: 10px;
}

.custemaildiv a:hover {
    color: #f5291e !important;
}


.product-video {
    width: 86px !important;
    height: 130px !important;
}

@media screen and (max-width: 768px) {
    .return-policy-checkbox {
        display: flex;
    }

    .return-policy-label {
        position: relative !important;
        left: 5px !important;
        font-size: 15px;
        margin-top: -5px;
        /* Adjust as needed */
        width: 100%;
        /* Ensure label takes full width */

    }

    .return-policy-checkbox input[type="checkbox"] {
        margin-bottom: 5px;
        /* Add space between checkbox and label */
    }

}

.competitors-price {
    text-decoration: line-through;
    opacity: .8;
    font-size: 20px !important;
}


.product-video {
    object-fit: inherit;
}

/* @media screen and (max-width: 768px) {
    .product-video {
        width: 160px !important;
        height: 160px !important;
    }
} */

/* @media screen and (min-width: 400px) {
    .product-video {
        width: 160px !important;
        height: 250px !important;
    }
  } */
.delete-preview-img {
    background-color: #e02e24;
    border: none;
}

.delete-preview-img:hover {
    background-color: #e02e24;
    border: none;
}

.video-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.video-item span {
    flex-grow: 2;
}

.delete-preview-img {
    margin-left: 10px;
    background-color: #e02e24 !important;
}

.sizechart-heading {
    background-color: #e02d2410 !important;
    text-align: center;
    font-size: 13.289px;
    font-style: normal;
    color: #2d393c;
    font-weight: 800;
    padding: 15px !important;
    vertical-align: middle;
    white-space: nowrap;
}


.size-chart-modal tbody,
.size-chart-modal tr {
    border-color: inherit;
    border-style: hidden;
    border-width: 0;

}

/* .size-chart-modal {
    border: 1px solid #2d393c;
} */


.amazone-text {
    font-weight: 600;
    color: #0f1111;
    font-family: "Amazon Ember", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    width: 86px !important;
}

.video-title {
    height: 240px !important;
}

.discount-percentage {
    font-size: 17px !important;
    color: #FB7181;
}

.slider_images {
    height: 500px !important;
}

@media only screen and (max-width: 767px) and (min-width: 200px) {

    .slider_pinfo .swiper-button-prev,
    .slider_pinfo .swiper-button-next {
        display: none;
    }

    .product-heading-name {
        font-size: 15px !important;
    }

    .product-info .per-pro h3 {
        font-size: 13px;
    }

    .product-info .per-pro .boughtitem {
        margin-bottom: -10px;
    }

    .discount-percentage {
        font-size: 13px !important;
    }

    .slider_images {
        height: 300px !important;
    }

    .spacing-top {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 768px) {
    table tbody td {
        padding: 15px 10px !important;
    }

    /* .dropdown {
        margin-top: 10px;
        height: 65px;
    } */

    .product-heading-name {
        display: none;
    }

    .product-info .per-pro h3 {
        font-weight: 700;
        font-size: 15px !important;
    }

    .policy-lab {
        font-size: 15px !important;
        cursor: pointer !important;

    }

    .modal-header {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 400px) {
    table tbody td {
        padding: 15px 10px !important;
    }

    /* .dropdown {
        margin-top: 10px;
        height: 75px;
    } */

    .product-info .per-pro h3 {
        font-weight: 700;
        font-size: 14px !important;


    }

    .product-info .per-pro .boughtitem {
        font-weight: 700;
        font-size: 14px !important;
        margin-bottom: -12px;

    }
}

.bottom-product-video {
    width: 100% !important;
    height: 100% !important;
}

.per-pro-container {
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px !important;
}

/* .video-design {
    height: 300px !important;
} */

figure img {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

figure {
    margin-bottom: 0px;
}

.modal-header {
    font-weight: 600;
    font-size: 15px;
    color: #0f1111;
    font-family: "Amazon Ember", Arial, sans-serif !important;
}

.policy-lab {
    font-size: 16px;
    color: #0f1111;
    /* font-family: "Amazon Ember", Arial, sans-serif; */
    cursor: pointer;
    font-weight: 700;
}



.scrollbar-custom::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.scrollbar-custom::-webkit-scrollbar {
    width: 12px;
    height: 5px;
    background-color: #F5F5F5;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #D62929;
}

.boughtitem-parent {
    margin-bottom: 5px !important;
}

.competitors-price {
    font-size: unset;
}

.productdetail {
    width: 100% !important;
    border: none !important;
}

.product-description {
    width: 100% !important;
    display: block !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #223263 !important;
}

.size-chart-link {
    font-size: 16px !important;
    line-height: 18px !important;
    text-decoration: none;
    color: #223263 !important;
    font-weight: 700;
}

.size-chart-link:hover {
    color: #223263 !important;
}

.slider-video {
    height: 760px;
}

.back-button {
    bottom: 85px;
}

@media only screen and (max-width: 767px) and (min-width: 200px) {
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 500px !important;
        /* object-fit: cover !important; */
    }

    .slider-video {
        height: 400px !important;
        object-fit: contain !important;
    }

    .product-img-slider-cos .image-gallery-thumbnails .image-gallery-thumbnails-container {
        display: flex;
        justify-content: center;
    }

    .product-info .per-pro span,
    .product-info .per-pro del {
        font-size: 14px !important;
    }

    .container-cos {
        max-width: 95%;
    }

    .image-gallery-thumbnails-wrapper {
        margin-left: 10px;
        margin-right: 10px;
    }

    /* .cart-button-sm {
        position: fixed;
        bottom: 10px;
        right: 0px;
        left: 0px;
        margin: 0px 10px;
        z-index: 9999;
    } */

    .cart-button-sm button {
        /* border-radius: 5px !important; */
        background-color: #ffd814 !important;
        color: #000;
        text-transform: uppercase;
        font-weight: 600;
        padding: 10px 20px;
    }

    .back-button {
        left: 20px;
    }
/* 
    .cart-top-main {
        bottom: 55px;
    } */

    .size-chart-modal td {
        padding: 10px 23px !important;
    }

    .number {
        top: 2px !important;
    }
}


@media only screen and (max-width: 1240px) and (min-width: 768px) {
    .product-info .per-pro h3 {
        font-size: 15px;
    }

    .discount-percentage {
        font-size: 15px !important;
    }

    .product-info .per-pro span {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 1240px) and (min-width: 1024px) {

    .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 450px !important;
        object-fit: cover !important;
    }

    .slider-video {
        height: 450px !important;
    }

}

/* Chart table Sm Size Start */
.chart-header p {
    width: 140px;
    border: 1px solid red;
    padding: 10px;
}

.chart-body p {
    border: 1px solid red;
    padding: 10px;
    text-wrap: nowrap;
}


@media only screen and (max-width: 576px) and (min-width: 200px) {

    .modal-body .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 300px !important;
        object-fit: contain !important;
    }
    .close-modal-btn {
        width: 20px;
        height: 10px;
    }
    .product-info .modal-dialog {
        margin-block: 8px;
    }

    .chart-sm-size {
        width: 500px;
    }

    .size-chart-link {
        font-size: 17px !important;
    }

    .policy-lab {
        font-size: 15px !important;
        font-weight: 600;
    }

    /* .video-design {
        height: 200px !important;
    } */

    .description-img-height {
        height: 100% !important;
        object-fit: cover;
    }

    figure img {
        /* height: 288px !important; */
        /* object-fit: fill; */
        aspect-ratio: auto !important;
    }

    .size-chart-link-res {
        line-height: 18px !important;
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
        color: #0f1111;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    figure img {
        /* width: 440px !important;
        height: 288px !important; */
        object-fit: contain;
        aspect-ratio: 500 / 0 !important;
    }

    figure.image {
        width: 500px;
    }

    .product-video {
        object-fit: contain;
    }

    /* .video-design {
        width: 440px !important;
    } */

    .description-img-height {
        /* width: 440px !important; */
        height: 100% !important;
    }

    .swiper-initialized.swiper-horizontal.mySwiper {
        /* width: 440px; */
        margin-left: 0px;
    }
}

.bg-redlight {
    background-color: #FCEAE9;
    color: #0f1111;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    font-size: 13px;
}

.bg-redlight .price {
    font-size: 15px;
}

.size-chart-modal-wrapper {
    /* padding: 20px; */
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    width: 100%;
}

.size-chart-table {
    width: 100%;
    border-collapse: collapse;
}

.size-chart-table td,
.size-chart-table th {
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;
}

.size-chart-table thead {
    background-color: #f5f5f5;
}


.size-chart-button.active {
    background-color: #007bff;
    color: #fff;
}


.size-chart-error {
    color: red;
    font-size: 1.5rem;
}

.z-99 {
    z-index: 99;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #F3F3F3 !important;
}

.table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-accent-bg: #fff !important;
}

/* th,
td {
    border-top: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
} */

/* thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
} */

/* .table-bordered {
    border: 1px solid #dee2e6;
} */

.size-chart-table-sm {
    /* width: 100%;
    margin: auto; */
    border-collapse: separate;
    /* border-spacing: 0; */
}

.product-info .product-color .cm-in-buttons button {
    background-color: red !important;
    /* border-radius: 8px; */
    padding: 4px 13px;
}

.product-info .product-color .cm-in-buttons .active {
    background-color: #b3221a !important;
    border: 0px !important;
    /* border-radius: 0px; */
}

/* .sticky-column {
    position: sticky;
    left: 0;
    background-color: #f0f2f2;
    z-index: 1;
} */

.size-guide {
    color: #007185;
    font-size: 16px;
    font-weight: 500;
}

th,
td {
    border-right: 1px solid #EBEBEB !important;
    border-bottom: 1px solid #EBEBEB !important;
}

table tr th:first-of-type {
    position: sticky;
    left: 0px;
}

table tr td:first-of-type {
    position: sticky;
    left: 0px;
}

.size-chart-tbody th {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
    text-wrap: nowrap;
}

.fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Adjust as necessary */
    cursor: pointer;
    /* To indicate it's clickable */
}

.fullscreen-content {
    max-width: 90%;
    max-height: 90%;
}

.fullscreen-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* Adjust as per your design */
}

.shipping-text {
    font-weight: 700;
    font-size: 14px;
    color: #0f1111;
}


.shipping-box .order-time {
    background: #e0ffff;
    border-radius: 4px;
    padding: 8px 15px;
}

.sel-delete-preview-img {
    position: relative;
    top: -48px;
    right: 14px;
    background-color: #979797 !important;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.a-text-bold {
    font-weight: 700 !important;
}

.text-black {
    color: #000000;
}

.review-video {
    width: 140px !important;
    height: 210px !important;
}

/* .number {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: yellow;
    color: #000;
    position: absolute;
    bottom: 70%;
    font-size: 12px;
    font-weight: 600;
    right: 0;
} */
.number {
    width: 18px;
    height: 18px;
    background-color: #E02E24;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 7px;
    right: 0px;
    border: 0px;
}

.yellow-btn {
    background-color: #FFD43E !important;
    color: #000000 !important;
    border-radius: 5px;
    height: 42px;
    font-weight: 500;
    font-size: 15px;
    border: none;
    box-shadow: none;
    margin-top: 30px;
}

.yellow-btn:hover,
.yellow-btn:focus,
.yellow-btn:active {
    background-color: #FFD43E !important;
    color: #000000 !important;
    border: none;
    box-shadow: none;
}

.shipping-address .shipping-address-btn {
    background-color: #FFD43E !important;
    color: #000000 !important;
    border-radius: 5px;
    height: 42px;
    font-weight: 600;
    font-size: 15px;
    border: none;
    box-shadow: none;
    padding: 10px 20px;
}

.outline-btn label {
    color: #000000 !important;
    font-weight: 700;
    font-size: 16px !important;
}

.location-main .shipping-address-btn {
    background-color: #FFD43E !important;
    color: #000000 !important;
    border-radius: 5px;
    height: 42px;
    font-weight: 600;
    font-size: 15px;
    border: none;
    box-shadow: none;
    padding: 10px 20px;
}

.check-options label {
    color: #000000 !important;
    font-weight: 700;
    font-size: 16px !important;
}

/* Table responsove css start */
@media only screen and (max-width: 1664px) and (min-width: 200px) {
    .order-table .table-hide {
        display: none;
    }

    .order-table .table-card-show {
        display: block !important;
    }

    .order-table .table-card-show h6 {
        color: #0f1111;
        text-align: left;
        font-size: 15px;
        font-weight: 600;
        /* overflow: hidden; */
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-wrap: wrap;
        line-height: 24px;
    }

    .order-table .table-card-show p,
    .order-table .table-card-show span {
        text-align: left;
        display: block;
        font-size: 15px;
        font-weight: 600;
        color: #0f1111;
        opacity: 0.5;
        text-wrap: wrap;
        word-break: break-all;
    }

    .order-table .table-card-show .bg-danger,
    .order-table .table-card-show .bg-success,
    .order-table .table-card-show .bg-info {
        padding: 6px 15px;
        border-radius: 50px;
        font-size: 15px;
        font-weight: normal;
        display: inline-block;
        color: #fff !important;
        opacity: 1 !important;
    }

    .order-table .table-card-show img {
        border-radius: 10px;
    }
}

@media only screen and (max-width: 1664px) and (min-width: 992px) {
    .order-table .table-card-show img {
        width: 200px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .order-table .table-card-show img {
        width: 100px;
    }
}

/* Table responsove css end */

.delivery-bg-color {
    background-color: #f7f7f5;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    color: #505050;
}

.shipping-text-xl {
    font-size: 15px;
    font-weight: 600;
    color: #0f1111;
}

@keyframes slideUpFadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .slide-up {
    opacity: 0; /* Initially hidden */
    animation: slideUpFadeIn 0.6s ease-out forwards; /* Adjust timing as needed */
  }
  